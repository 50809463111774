import JSZip from 'jszip'
import FileSaver from 'file-saver'
import * as math from 'mathjs'
import * as ml from '../views/ecoplot-desktops/tabs/machine-learning/helpers.js'
import * as tf from '@tensorflow/tfjs'
const _ = require('lodash')
const MODEL_CONFIG = {
  units: 12,
  dropout: 0.2,
  clipvalue: 1,
  patience: 100,
  minDelta: 0.0001,
}

var modelData = null
var stopTraining = false
var model = null

export default {
  data() {
    return {
      workerReturnBatchEnd: null,
      workerReturnEpochEnd: null,
      workerReturnTrainingModelDone: null,
      workerReturnPredictModelDone: null,
      workerReturnEvaluateModelDone: null,
      workerReturnImportModelDone: null,
    }
  },
  mounted() {
    // Update Form Data
    // ipcRenderer.on('on-worker-update-form', (_, data) => {
    //   if (modelData) {
    //     modelData.formData = data
    //   }
    // })
    // TRAINING
    // ipcRenderer.on('on-worker-training-model', (_, data) => {
    //   this.trainingModel(data)
    // })
    // ipcRenderer.on('on-worker-stop-training-model', (_) => {
    //   stopTraining = true
    // })
    // ipcRenderer.on('on-worker-remove-model', (_) => {
    //   model = null
    // })
    // PREDICTING
    // ipcRenderer.on('on-worker-predict-model', (_) => {
    //   this.predictModel()
    // })
    // EVALUATING
    // ipcRenderer.on('on-worker-evaluate-model', (_, data) => {
    //   this.evaluateModel(data)
    // })
    // IMPORT & EXPORT
    // ipcRenderer.on('on-worker-import-model', (_, data) => {
    //   this.importModel(data)
    // })
    // ipcRenderer.on('on-worker-export-model', (_, selectedItem, datasourceName) => {
    //   this.exportModel(selectedItem, datasourceName)
    // })
  },
  methods: {
    workerUpdateForm(data) {
      if (modelData) {
        modelData.formData = data
      }
    },
    workerRemoveModel() {
      model = null
    },
    workerStopTrainningModel() {
      stopTraining = true
    },
    // evaluateModel(data) {
    workerEvaluateModel(data) {
      modelData = data
      const { formData } = modelData
      const batchSize = Number(formData.batchSize)
      const evaluateModel = formData.modelType === 'UL' ? this.evaluateSingleLSTMModel : this.evaluateMultiLSTMModel
      const { x_test, y_test, nFeatures = 1 } = evaluateModel()

      // Compile
      model.compile({ loss: formData.lossFunction, optimizer: formData.optimizer })

      const x_test_tensor = tf.tensor3d(x_test)
      const y_test_tensor = tf.tensor2d(y_test, [y_test.length, nFeatures])
      const result = model.evaluate(x_test_tensor, y_test_tensor, { batchSize })

      const loss = Number(result.dataSync()).toFixed(4)

      this.workerReturnEvaluateModelDone = { ...this.getModelInfo(model), loss }
    },
    async workerTraningModel(data) {
      stopTraining = false
      modelData = data
      const { formData, validatingPercent } = modelData
      const trainModel = formData.modelType === 'UL' ? this.trainSingleLSTMModel : this.trainMultiLSTMModel
      const { x_train, y_train, nFeatures = 1 } = trainModel()

      model = tf.sequential()
      model.add(tf.layers.lstm({ units: 12, activation: formData.activation, inputShape: [formData.lookBack, nFeatures], returnSequences: true }))
      model.add(tf.layers.dropout({ rate: MODEL_CONFIG.dropout }))
      model.add(tf.layers.lstm({ units: MODEL_CONFIG.units, activation: formData.activation, return_sequences: false }))
      model.add(tf.layers.dropout({ rate: MODEL_CONFIG.dropout }))
      model.add(tf.layers.dense({ units: nFeatures }))

      // Compile
      model.compile({ loss: formData.lossFunction, optimizer: formData.optimizer, metrics: ['accuracy'] })

      // Training Model
      const x_train_tensor = tf.tensor3d(x_train)
      const y_train_tensor = tf.tensor2d(y_train, [y_train.length, nFeatures])
      const batchSize = Number(formData.batchSize)
      const numberOfEpochs = formData.epoch
      const numberStepsOfBatch = Math.ceil(Math.floor(x_train.length - (x_train.length * validatingPercent) / 100) / batchSize)
      let self = this
      await model.fit(x_train_tensor, y_train_tensor, {
        epochs: numberOfEpochs,
        batchSize,
        validationSplit: validatingPercent / 100,
        callbacks: {
          onBatchEnd(batch, log) {
            if (stopTraining) {
              model.stopTraining = true
              return
            }
            self.workerReturnBatchEnd = { log, batch, numberStepsOfBatch }
          },
          onEpochEnd(epoch, log) {
            if (stopTraining) {
              model.stopTraining = true
              return
            }
            self.workerReturnEpochEnd = { log, epoch }
          },
        },
        yieldEvery: 'epoch',
      })
      this.workerReturnTrainingModelDone = this.getModelInfo(model)
    },
    workerPredictModel(data) {
      setTimeout(() => {
        if (modelData) modelData.predictData = data.predictData
        const { formData } = modelData
        const predictFunction = formData.modelType === 'UL' ? this.predictSingleLSTMModel : this.predictMultiLSTMModel
        const predictTraces = predictFunction()
        this.workerReturnPredictModelDone = predictTraces
      }, 0)
    },
    async workerImportModel(data) {
      modelData = data
      const fileContents = JSON.parse(window.localStorage.getItem('modelFiles'))
      const files = fileContents.map((contents) => ml.dataURLtoFile(contents.content, contents.name))
      model = await tf.loadLayersModel(tf.io.browserFiles(files))
      window.localStorage.removeItem('modelFiles')
      this.workerReturnImportModelDone = this.getModelInfo(model)
    },
    async workerExportModel({ item: selectedItem, location: selectedLocation }, datasourceName) {
      const downloadPath = 'localstorage://' + selectedItem
      await model.save(downloadPath)

      let myStorage = window.localStorage
      const tensorflowModelPath = 'tensorflowjs_models/' + selectedItem
      // Model architecture
      const weightSpecs = myStorage.getItem(tensorflowModelPath + '/weight_specs')
      const modelTopo = myStorage.getItem(tensorflowModelPath + '/model_topology')
      const modelMeta = myStorage.getItem(tensorflowModelPath + '/model_metadata')

      let modelText = '{' + '"modelTopology":' + modelTopo + ',' + modelMeta.slice(1, -1) + ','
      modelText += '"weightsManifest":[{"paths":["./' + selectedItem + '.weights.bin"],"weights":'
      modelText += weightSpecs + '}]}'
      const modelJson = JSON.parse(modelText)
      // Model weights
      const modelWeights = myStorage.getItem(tensorflowModelPath + '/weight_data')

      // Zip file
      let zip = new JSZip()

      // Save model architecture json file
      let blob = new Blob([JSON.stringify(modelJson)], { type: 'text/plain;charset=utf-8' })
      let jsonFile = selectedItem + '.json'
      zip.file(jsonFile, blob)

      // Save model weights bin file
      let bufString = window.atob(modelWeights)
      let byteNumbers = new Array(bufString.length)
      for (let i = 0; i < bufString.length; i++) {
        byteNumbers[i] = bufString.charCodeAt(i)
      }
      let byteArray = new Uint8Array(byteNumbers)
      blob = new Blob([byteArray], { type: 'application/octet-stream' })
      let binFile = selectedItem + '.weights.bin'
      zip.file(binFile, blob)

      // Download zip file
      let time = new Date()
      time = '' + time.getFullYear() + (time.getMonth() + 1) + time.getDate() + time.getHours() + time.getMinutes() + time.getSeconds()
      let saveZipPath = `${datasourceName}_${selectedLocation}_${selectedItem}`
      saveZipPath = time + saveZipPath + '.zip'
      zip.generateAsync({ type: 'blob' }).then((content) => {
        FileSaver.saveAs(content, saveZipPath)
      })

      // Clear local storage
      myStorage.removeItem(tensorflowModelPath + '/weight_specs')
      myStorage.removeItem(tensorflowModelPath + '/model_topology')
      myStorage.removeItem(tensorflowModelPath + '/model_metadata')
      myStorage.removeItem(tensorflowModelPath + '/weight_data')
      myStorage.removeItem(tensorflowModelPath + '/info')
    },
    getModelInfo(myModel) {
      let loss = null
      if (myModel.model && myModel.model.history) {
        loss = myModel.model.history.history.loss.reduce((a, b) => a + b, 0).toFixed(4)
      }
      return { loss, layers: myModel.layers.length }
    },
    predictSingleLSTMModel() {
      let { dataArray, trainingData, testingData, formData, predictIndex, predictData } = modelData
      // 1. Tìm indexes của Item & Station đang chọn
      const originDate = dataArray.map((i) => i[0])
      const originData = dataArray.map((i) => i[1])
      trainingData = trainingData.map((i) => i[1])
      testingData = testingData.map((i) => i[1])

      // 2. Standardize data
      const dataStd = math.std(originData)
      const dataMean = math.mean(originData)
      const originDataStandardize = ml.standardizeData(originData, dataStd, dataMean)
      const trainingDataStandardize = ml.standardizeData(trainingData, dataStd, dataMean)
      const testingDataStandardize = ml.standardizeData(testingData, dataStd, dataMean)

      // 3. Build LSTM data
      // 3.1 Convert data into matrix
      const trainingMatrix = ml.buildSingleLSTMMatrix(trainingDataStandardize, formData.lookBack)
      const testingMatrix = ml.buildSingleLSTMMatrix(testingDataStandardize, formData.lookBack)

      // 3.2 Build XY data
      let x_train = trainingMatrix
      let x_test = testingMatrix

      // 3.3 Convert to 2D/3D shape
      x_train = ml.reshape3DArray(x_train)
      x_test = ml.reshape3DArray(x_test)
      let x_future = originDataStandardize.slice(predictIndex - formData.lookBack + 1, predictIndex + 1)

      // 6. Training Model
      const x_train_tensor = tf.tensor3d(x_train)

      // 7. Predict with Model
      // Predict Training data
      let x_predict_training_result = model.predict(x_train_tensor, { batch_size: formData.batchSize })
      let x_predict_training = x_predict_training_result.dataSync()

      // Predict Testing data
      let x_test_tensor = tf.tensor3d(x_test)
      let x_predict_testing_result = model.predict(x_test_tensor, { batch_size: formData.batchSize })
      let x_predict_testing = x_predict_testing_result.dataSync()

      // Predict Future data
      let x_predict_future = []
      for (let i = 0; i < formData.predictFuture; i++) {
        let tensorXFuture = tf.tensor3d(x_future, [1, x_future.length, 1])
        let x_predict_future_result = model.predict(tensorXFuture, { batch_size: formData.batchSize })
        let x_predict_future_data = x_predict_future_result.dataSync()[0]
        x_predict_future.push(x_predict_future_data)

        // Update predict x_future
        x_future.shift()
        x_future.push(x_predict_future_data)
      }

      // 8. De-standardize data
      x_predict_training = ml.destandardizeData(x_predict_training, dataStd, dataMean)
      x_predict_testing = ml.destandardizeData(x_predict_testing, dataStd, dataMean)
      x_predict_future = ml.destandardizeData(x_predict_future, dataStd, dataMean)

      // 9. Draw on chart
      let x_training_date = originDate.slice(formData.lookBack)
      let x_testing_date = originDate.slice(trainingData.length + formData.lookBack)
      let x_future_date = predictData.map((i) => i[0])

      let predictTraces = []
      predictTraces.push({ name: 'Training', x: originDate, y: originData, type: 'scatter', mode: 'lines', line: { color: '#337ab7' }, hovertemplate: `(%{x|%Y/%m/%d %H:%M:%S}, %{y})` })
      predictTraces.push({ name: 'Predict Training', x: x_training_date, y: x_predict_training, type: 'scatter', mode: 'lines', line: { color: '#337ab7', dash: 'dash' }, hovertemplate: `(%{x|%Y/%m/%d %H:%M:%S}, %{y})` })
      predictTraces.push({ name: 'Predict Testing', x: x_testing_date, y: x_predict_testing, type: 'scatter', mode: 'lines', line: { color: '#5cb85c', dash: 'dash' }, hovertemplate: `(%{x|%Y/%m/%d %H:%M:%S}, %{y})` })
      predictTraces.push({ name: 'Predict Future', x: x_future_date, y: x_predict_future, type: 'scatter', mode: 'lines', line: { color: '#b85cb5', dash: 'dash' }, hovertemplate: `(%{x|%Y/%m/%d %H:%M:%S}, %{y})` })
      return predictTraces
    },
    predictMultiLSTMModel() {
      let { dataArray, featuresArray, trainingData, formData, predictIndex, predictData } = modelData

      // 1. General data
      let originDate = dataArray.map((i) => i[0])
      let originData = dataArray.map((i) => i[1])

      // Number of features
      featuresArray = _.cloneDeep(featuresArray)
      let nFeatures = featuresArray.unshift(dataArray)

      let selectedItemTrainData = trainingData.map((i) => i[1])
      let dataStd = math.std(originData)
      let dataMean = math.mean(originData)

      let featuresOriginData = featuresArray.map((el) => el.map((x) => x[1]))
      let featuresTrainData = featuresArray.map((el) => el.slice(0, selectedItemTrainData.length).map((x) => x[1]))
      let featuresTestData = featuresArray.map((el) => el.slice(selectedItemTrainData.length).map((x) => x[1]))

      // 2. Standardize data

      let featuresOriginStandardize = ml.standardizeFeaturesData(featuresOriginData, 0)
      let featuresTrainStandardize = ml.standardizeFeaturesData(featuresOriginData, featuresTrainData)
      let featuresTestStandardize = ml.standardizeFeaturesData(featuresOriginData, featuresTestData)

      // 3. Build LSTM data
      // 3.1 Convert data into matrix
      let trainingMatrix = ml.buildMultiLSTMMatrix(featuresTrainStandardize, formData.lookBack)
      let testingMatrix = ml.buildMultiLSTMMatrix(featuresTestStandardize, formData.lookBack)

      // 3.2 Build XY data
      let x_train = ml.transformFeatures(trainingMatrix)
      let x_test = ml.transformFeatures(testingMatrix)

      // x_future get value at PredictIndex
      let x_future = featuresOriginStandardize.map((el) => el.slice(predictIndex - formData.lookBack + 1, predictIndex + 1))
      x_future = [ml.convertFeatures(x_future, 0)]

      // // 6. Training Model
      const x_train_tensor = tf.tensor3d(x_train)

      // 7. Predict with Model
      // Predict Training data
      let x_predict_training_result = model.predict(x_train_tensor, { batch_size: formData.batchSize })
      let x_predict_training = x_predict_training_result.arraySync().map((el) => el[0])

      // Predict Testing data
      let x_test_tensor = tf.tensor3d(x_test)
      let x_predict_testing_result = model.predict(x_test_tensor, { batch_size: formData.batchSize })
      let x_predict_testing = x_predict_testing_result.arraySync().map((el) => el[0])

      // Predict Future data
      let x_predict_future = []
      for (let i = 0; i < formData.predictFuture; i++) {
        let tensorXFuture = tf.tensor3d(x_future, [1, formData.lookBack, nFeatures])
        let x_predict_future_result = model.predict(tensorXFuture, { batch_size: formData.batchSize })
        let x_predict_future_data = x_predict_future_result.dataSync()
        x_predict_future.push(x_predict_future_data[0])

        // Update predict x_future
        x_future[0].shift()
        x_future[0].push(x_predict_future_data)
      }

      // 8. De-standardize data
      x_predict_training = ml.destandardizeData(x_predict_training, dataStd, dataMean)
      x_predict_testing = ml.destandardizeData(x_predict_testing, dataStd, dataMean)
      x_predict_future = ml.destandardizeData(x_predict_future, dataStd, dataMean)

      // 9. Draw on chart
      let x_training_date = originDate.slice(formData.lookBack)
      let x_testing_date = originDate.slice(trainingData.length + formData.lookBack)
      let x_future_date = predictData.map((i) => i[0])

      let predictTraces = []
      predictTraces.push({ name: 'Training', x: originDate, y: originData, type: 'scatter', mode: 'lines', line: { color: '#337ab7' }, hovertemplate: `(%{x|%Y/%m/%d %H:%M:%S}, %{y})` })
      predictTraces.push({ name: 'Predict Training', x: x_training_date, y: x_predict_training, type: 'scatter', mode: 'lines', line: { color: '#337ab7', dash: 'dash' }, hovertemplate: `(%{x|%Y/%m/%d %H:%M:%S}, %{y})` })
      predictTraces.push({ name: 'Predict Testing', x: x_testing_date, y: x_predict_testing, type: 'scatter', mode: 'lines', line: { color: '#5cb85c', dash: 'dash' }, hovertemplate: `(%{x|%Y/%m/%d %H:%M:%S}, %{y})` })
      predictTraces.push({ name: 'Predict Future', x: x_future_date, y: x_predict_future, type: 'scatter', mode: 'lines', line: { color: '#b85cb5', dash: 'dash' }, hovertemplate: `(%{x|%Y/%m/%d %H:%M:%S}, %{y})` })
      return predictTraces
    },
    evaluateSingleLSTMModel() {
      let { dataArray, testingData, formData } = modelData
      const originData = dataArray.map((i) => i[1])
      testingData = testingData.map((i) => i[1])

      // 2. Standardize data
      const dataStd = math.std(originData),
        dataMean = math.mean(originData)
      var testingDataStandardize = ml.standardizeData(testingData, dataStd, dataMean)

      // 3. Build LSTM data
      // 3.1 Convert data into matrix
      const testingMatrix = ml.buildSingleLSTMMatrix(testingDataStandardize, formData.lookBack)

      // 3.2 Build XY data
      let x_test = testingMatrix
      let y_test = testingDataStandardize.slice(formData.lookBack, testingDataStandardize.length)

      // 3.3 Convert to 2D/3D shape
      x_test = ml.reshape3DArray(x_test)
      y_test = ml.reshape2DArray(y_test)

      return { x_test, y_test }
    },
    evaluateMultiLSTMModel() {
      let { dataArray, testingData, featuresArray, formData } = modelData

      // Number of features
      featuresArray = _.cloneDeep(featuresArray)
      let nFeatures = featuresArray.unshift(dataArray)
      let selectedItemTestData = testingData.map((i) => i[1])

      let featuresOriginData = featuresArray.map((el) => el.map((x) => x[1]))
      let featuresTestData = featuresArray.map((el) => el.slice(selectedItemTestData.length).map((x) => x[1]))

      // 2. Standardize data
      let featuresTestStandardize = ml.standardizeFeaturesData(featuresOriginData, featuresTestData)

      // 3. Build LSTM data
      // 3.1 Convert data into matrix
      let testingMatrix = ml.buildMultiLSTMMatrix(featuresTestStandardize, formData.lookBack)

      // 3.2 Build XY data
      let x_test = ml.transformFeatures(testingMatrix)
      let y_test = ml.convertFeatures(featuresTestStandardize, formData.lookBack)

      return { x_test, y_test, nFeatures }
    },
    trainSingleLSTMModel() {
      let { dataArray, trainingData, formData } = modelData
      const originData = dataArray.map((i) => i[1])
      trainingData = trainingData.map((i) => i[1])

      // 2. Standardize data
      const dataStd = math.std(originData),
        dataMean = math.mean(originData)
      const trainingDataStandardize = ml.standardizeData(trainingData, dataStd, dataMean)

      // 3. Build LSTM data
      // 3.1 Convert data into matrix
      const trainingMatrix = ml.buildSingleLSTMMatrix(trainingDataStandardize, formData.lookBack)

      // 3.2 Build XY data
      let x_train = trainingMatrix
      let y_train = trainingDataStandardize.slice(formData.lookBack, trainingDataStandardize.length)

      // 3.3 Convert to 2D/3D shape
      x_train = ml.reshape3DArray(x_train)
      y_train = ml.reshape2DArray(y_train)

      return { x_train, y_train }
    },
    trainMultiLSTMModel() {
      let { dataArray, trainingData, featuresArray, formData } = modelData

      // Number of features
      featuresArray = _.cloneDeep(featuresArray)
      let nFeatures = featuresArray.unshift(dataArray)
      let selectedItemTrainData = trainingData.map((i) => i[1])

      let featuresOriginData = featuresArray.map((el) => el.map((x) => x[1]))
      let featuresTrainData = featuresArray.map((el) => el.slice(0, selectedItemTrainData.length).map((x) => x[1]))

      // 2. Standardize data
      let featuresTrainStandardize = ml.standardizeFeaturesData(featuresOriginData, featuresTrainData)

      // 3. Build LSTM data
      // 3.1 Convert data into matrix
      let trainingMatrix = ml.buildMultiLSTMMatrix(featuresTrainStandardize, formData.lookBack)

      // 3.2 Build XY data
      let x_train = ml.transformFeatures(trainingMatrix)
      let y_train = ml.convertFeatures(featuresTrainStandardize, formData.lookBack)

      return { x_train, y_train, nFeatures }
    },
  },
}
