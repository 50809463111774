<template>
  <div class="dynamic-animate" :animate-id="animateID" :class="`${animate} ${position}`">
    <div class="animate-trigger" :class="{'show': isShow}" @click="handleClick" @focus="handleFocus" @mouseover="handleMouseover" @mouseleave="handleMouseleave">
      <slot></slot>
    </div>
    <div class="animate-content" :class="{'show': isShow}">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

const TRIGGER = {
  CLICK: 'click',
  HOVER: 'hover',
  FOCUS: 'focus',
}
const POSITION = {
  BUTTON_LEFT: 'bottom-left',
  BUTTON: 'bottom',
  BUTTON_RIGHT: 'bottom-right',
  TOP_LEFT: 'top-left',
  TOP: 'top',
  TOP_RIGHT: 'top-right',
  LEFT: 'left',
  RIGHT: 'right',
}
const ANIMATIONS = {
  FADEIN_DOWN: 'fadein-down',
}

export default {
  props: {
    trigger: {
      type: String,
      default: TRIGGER.CLICK,
    },
    position: {
      type: String,
      default: POSITION.BUTTON,
    },
    animate: {
      type: String,
      default: ANIMATIONS.FADEIN_DOWN,
    },
  },
  data() {
    return {
      animateID: '',
      isShow: false,
    }
  },
  created() {
    this.animateID = uuidv4();
  },
  mounted() {
    this.addListenersClick();
  },
  unmounted() {
    this.removeListenersClick();
  },
  methods: {
    addListenersClick() {
      if (this.trigger === TRIGGER.CLICK) {
        document.body.addEventListener('click', this.bodyClickHandler, true);
      }
    },
    removeListenersClick() {
      document.body.removeEventListener('click', this.bodyClickHandler);
    },
    bodyClickHandler(e) {
      const target = e.target;
      const isChildOfAnimate = (target.closest(`[animate-id="${this.animateID}"`) != null);
      const isTrigger = isChildOfAnimate && target.closest(`.animate-trigger`) != null;
      const isNotCloseEl = isChildOfAnimate && target.closest('.click-not-close') != null;

      if (!isTrigger && !isNotCloseEl) {
        this.isShow = false;
      }
    },
    handleClick() {
      if (this.trigger === TRIGGER.CLICK) {
        this.isShow = !this.isShow
      }
    },
    handleFocus() {
      if (this.trigger === TRIGGER.FOCUS) {
        console.log('handleFocus')
      }
    },
    handleMouseover() {
      if (this.trigger === TRIGGER.HOVER) {
        console.log('handleMouseover')
      }
    },
    handleMouseleave() {
      if (this.trigger === TRIGGER.HOVER) {
        console.log('handleMouseleave')
      }
    },
  },
}
</script>

<style>
.dynamic-animate {
  cursor: pointer;
  z-index: 11;
  position: relative;
}
.dynamic-animate .animate-content {
  opacity: 0;
  transform: scale(0);
  transition: transform 0.4s;
}
.dynamic-animate .animate-content.show {
  opacity: 1;
  transform: scale(1);
}
/* Fadein down */
.fadein-down .animate-content {
  position: absolute;
  top: 20px;
  transition: top 0.4s, opacity 0.4s;
}
.fadein-down .animate-content.show {
  top: 50px;
}
</style>
