var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-slider-process-container"},[_c('VueSlider',{ref:"refSlider",staticClass:"mb-3",attrs:{"marks":true,"order":false,"process":_vm.process},on:{"dragging":_vm.handleDragging},scopedSlots:_vm._u([{key:"mark",fn:function(ref){
var pos = ref.pos;
var label = ref.label;
return [_c('div',{staticClass:"vue-slider-mark vue-slider-mark-active",style:({ left: (pos + "%") })},[_c('div',{staticClass:"vue-slider-mark-step vue-slider-mark-step-active"}),_c('div',{staticClass:"vue-slider-mark-label vue-slider-mark-label-active"},[_vm._v(_vm._s(_vm.showLabel(label)))])])]}},{key:"dot",fn:function(ref){
var index = ref.index;
var focus = ref.focus;
return [_c('div',{staticClass:"vue-slider-dot-handle",class:{ 'vue-slider-dot-handle-focus': focus },style:({ borderColor: _vm.colors[index] })})]}},{key:"process",fn:function(ref){
var style = ref.style;
var start = ref.start;
return [_c('div',{staticClass:"vue-slider-process",style:(_vm.trainingStyles(style, start))}),_c('div',{staticClass:"vue-slider-process",style:(style)})]}},{key:"tooltip",fn:function(ref){
var index = ref.index;
return [_c('div',{staticClass:"vue-slider-dot-tooltip-inner vue-slider-dot-tooltip-inner-top"},[_c('span',{staticClass:"vue-slider-dot-tooltip-text"},[_vm._v(_vm._s(_vm.tooltips[index]))])])]}}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }