<template>
  <div class="machine-learning-container">
    <b-card class="machine-learning-card custom-card">
      <b-row class="flex-nowrap d-flex align-items-center">
        <b-col cols="auto">
          <b-form-group class="mb-0" style="min-width: 300px">
            <DatasourceSelect v-model="datasourceSelected" mode="single" :disabled="isTraining" />
          </b-form-group>
        </b-col>
        <b-col cols="auto" class="d-flex">
          <b-form-group class="mb-0 ml-model-config">
            <label class="toolbar-title text-primary">{{ $t('model') }}</label>
            <div class="d-flex model-actions">
              <b-media-aside>
                <b-button class="model-actions_item model-actions_item-config-model position-relative" @click="resetModelTypeGroup" rounded variant="light-primary" size="sm">
                  <feather-icon icon="CpuIcon" size="18" />
                  <b-spinner v-if="isTraining || isEvaluating" class="spinner-model" variant="primary" type="grow" />
                  <MlAdvancedConfig @removeModel="removeModel" @evaluateModel="evaluateModel" @exportModel="exportLSTMModel" :isEvaluating="isEvaluating" :batchTrainingProgress="batchTrainingProgress" :epochTrainingProgress="epochTrainingProgress" :itemsChoices="featureItemsChoices" :isDisabled="isTraining || showLoading || !firstSelectedItem" ref="refMlAdvancedConfig" :modelInfo="modelInfo" v-model="formData" :totalCount="totalCount" @importModel="importMModel" />
                </b-button>
              </b-media-aside>
              <b-media-aside :title="$t('training')" :disabled="disableTraining" @click="trainingModel">
                <b-avatar rounded :variant="isTraining ? 'light-danger' : 'light-success'" size="32">
                  <b-spinner small v-if="isTraining" />
                  <feather-icon icon="ActivityIcon" size="18" v-else />
                  <span class="action-title">{{ isTraining ? $t('stop') : $t('train') }}</span>
                </b-avatar>
              </b-media-aside>
              <b-media-aside :title="$t('predict')" :disabled="disablePredicting" @click="predictModel">
                <b-avatar rounded variant="light-purple" size="32">
                  <feather-icon icon="CommandIcon" size="18" />
                  <span class="action-title">{{ $t('predict') }}</span>
                </b-avatar>
              </b-media-aside>
            </div>
          </b-form-group>
          <b-form-group class="mb-0 ml-model-config ml-model-config-setting" :title="$t('option_chart')">
            <label class="toolbar-title text-primary">{{ $t('option_chart') }}</label>
            <div class="d-flex model-actions d-flex align-center align-items-center justify-content-center">
              <b-media-aside>
                <b-button class="model-actions_item model-actions_item-option-chart position-relative mr-0" style="width: 32px; height: 32px" rounded variant="light-primary" size="sm">
                  <feather-icon icon="SettingsIcon" size="18" />
                  <MlAdvancedSetting :nameComponent="'machine_learning'" />
                </b-button>
              </b-media-aside>
            </div>
          </b-form-group>
        </b-col>
        <div class="ml-auto d-flex align-items-center">
          <MlSummary :summary="summaryML" @sliderChange="handleSliderChange" ref="refMlSummary" />
          <Downloader :downloadImage="downloadImage" :downloadHtml="downloadHtml" :downloadModel="exportLSTMModel" style="margin-right: 15px" />
        </div>
      </b-row>
    </b-card>
    <b-overlay :show="showLoading" variant="transparent" opacity="0.85" blur="2px" rounded="sm">
      <MachineLearningChart :chartList="chartList" ref="refChart" :nameComponent="'machine_learning'" />
    </b-overlay>
  </div>
</template>

<script>
import Plotly from 'plotly.js-dist'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import SelectDatasourceMixin from '@/mixins/SelectDatasourceMixin'
import MachineLearningMixin from '@/mixins/MachineLearningMixin'
import { CustomLayout } from '@/mixins/ThemeMixin.js'
import DownloadMixin from '@/mixins/DownloadMixin'
import MachineLearningWorkerMixin from '@/mixins/MachineLearningWorkerMixin'
import DatasourceSelect from '../../common/DatasourceSelect/DatasourceSelect.vue'
import Downloader from '../../common/Downloader.vue'
import { readFilesBase64 } from '@/views/ecoplot-desktops/tabs/machine-learning/helpers'
import MlSummary from './MlSummary.vue'
import MachineLearningChart from './MachineLearningChart.vue'
import MlAdvancedConfig from './MlAdvancedConfig.vue'
import i18n from '@/libs/i18n'
import MlAdvancedSetting from './MlAdvancedSetting.vue'
const LAYOUT = {
  hovermode: 'closest',
  showlegend: false,
  margin: { l: 5, r: 5, b: 5, t: 5, pad: 2 },
  xaxis: { automargin: true, visible: false },
  yaxis: { automargin: true, visible: false },
}
const CONFIG = {
  displaylogo: false,
  responsive: true,
  editable: false,
  displayModeBar: false,
}

export default {
  name: 'machine_learning',
  components: { DatasourceSelect, Downloader, MlAdvancedConfig, MlSummary, MachineLearningChart, MlAdvancedSetting },
  mixins: [SelectDatasourceMixin, MachineLearningMixin, CustomLayout, DownloadMixin, MachineLearningWorkerMixin, ToastificationContent],
  mounted() {
    this.initializeHistorydata()
  },
  data() {
    return {
      formData: {},
      validatingPercent: 20,
      testingPercent: 20,
      predictPercent: 100,
      // MODEL
      modelInfo: null,
      predictTraces: [],
      // CONFIG
      isEvaluating: false,
      isTraining: false,
      showLoading: false,
      // Model Training Data
      batchEndData: [],
      epochEndData: [],
      batchTrainingProgress: {},
      epochTrainingProgress: {},
    }
  },
  computed: {
    disablePredicting() {
      return !this.firstSelectedItem || this.isEvaluating || this.isTraining || !this.modelInfo || this.showLoading
    },
    disableTraining() {
      return !this.firstSelectedItem || this.isEvaluating || this.showLoading
    },
    tempProject() {
      return this.$store.state.ecoplot.tempProject
    },
    initProject() {
      return this.$store.state.ecoplot.initProject
    },
    locale() {
      return i18n.locale
    },
  },

  watch: {
    locale() {
      this.changeLocale()
    },
    initProject() {
      this.formData = {}
      this.validatingPercent = 20
      this.testingPercent = 20
      this.predictPercent = 100
      this.modelInfo = null
      this.predictTraces = []
      this.isEvaluating = false
      this.isTraining = false
      this.showLoading = false
      this.batchEndData = []
      this.epochEndData = []
      this.batchTrainingProgress = {}
      this.epochTrainingProgress = {}
    },
    formData() {
      this.workerUpdateForm(this.formData)
    },
    batchEndData() {
      Plotly.react(this.$refs.refMlAdvancedConfig.$refs.refBatchendChart, this.batchEndData, this.getLayout({ ...LAYOUT }), CONFIG)
    },
    epochEndData() {
      Plotly.react(this.$refs.refMlAdvancedConfig.$refs.refEpochendChart, this.epochEndData, this.getLayout({ ...LAYOUT }), CONFIG)
    },
    workerReturnTrainingModelDone: {
      deep: true,
      handler() {
        try {
          this.modelInfo = this.workerReturnTrainingModelDone
          this.isTraining = false
        } catch {}
      },
    },
    workerReturnBatchEnd: {
      deep: true,
      handler() {
        try {
          let { batch, log, numberStepsOfBatch } = this.workerReturnBatchEnd
          this.showLoading = false
          this.batchTrainingProgress = {
            current: (this.epochTrainingProgress.current || 0) * numberStepsOfBatch + batch + 1,
            total: numberStepsOfBatch * this.formData.epoch,
            percentage: (this.epochTrainingProgress.percentage || 0) + ((batch + 1) / numberStepsOfBatch / this.formData.epoch) * 100,
          }
          this.batchEndData.splice(0, 1, { ...this.batchEndData[0], connectgaps: false, x: [...this.batchEndData[0].x, this.batchTrainingProgress.current - 1], y: [...this.batchEndData[0].y, log.loss] })
        } catch {}
      },
    },
    workerReturnEpochEnd: {
      deep: true,
      handler() {
        try {
          let { epoch, log } = this.workerReturnEpochEnd
          this.showLoading = false
          this.epochTrainingProgress = {
            current: epoch + 1,
            total: this.formData.epoch,
            percentage: ((epoch + 1) / this.formData.epoch) * 100,
          }
          this.epochEndData.splice(0, 1, { ...this.epochEndData[0], x: [...this.epochEndData[0].x, epoch], y: [...this.epochEndData[0].y, log.loss] })
          this.epochEndData.splice(1, 1, { ...this.epochEndData[1], x: [...this.epochEndData[1].x, epoch], y: [...this.epochEndData[1].y, log.val_loss] })
        } catch {}
      },
    },
    workerReturnPredictModelDone: {
      deep: true,
      handler() {
        try {
          this.showLoading = false
          this.predictTraces = this.workerReturnPredictModelDone
          this.changeLocale()
        } catch {}
      },
    },
    workerReturnEvaluateModelDone: {
      deep: true,
      handler() {
        try {
          this.showLoading = false
          this.isEvaluating = false
          this.modelInfo = this.workerReturnEvaluateModelDone
        } catch {}
      },
    },
    workerReturnImportModelDone: {
      deep: true,
      handler() {
        try {
          this.modelInfo = this.workerReturnImportModelDone
        } catch {}
      },
    },
  },
  methods: {
    changeLocale() {
      if (this.predictTraces.length > 0) {
        if (this.locale === 'en') {
          this.predictTraces[0].name = 'Training'
          this.predictTraces[1].name = 'Predict Training'
          this.predictTraces[2].name = 'Predict Testing'
          this.predictTraces[3].name = 'Predict Future'
        } else {
          this.predictTraces[0].name = 'トレーニングデータ'
          this.predictTraces[1].name = '予測トレーニング'
          this.predictTraces[2].name = '予測テスト'
          this.predictTraces[3].name = '未来を予測する'
        }
      }
    },
    evaluateModel() {
      if (!this.checkModelBeforeTrain()) return
      this.isEvaluating = true
      this.showLoading = true
      this.workerEvaluateModel({
        dataArray: this.dataArray,
        trainingData: this.trainingData,
        featuresArray: this.featuresArray,
        testingData: this.testingData,
        predictIndex: this.predictIndex,
        formData: this.formData,
        validatingPercent: this.validatingPercent,
        predictData: this.predictData,
      })
    },
    removeModel() {
      this.modelInfo = null
      this.initializeHistorydata()
      this.workerRemoveModel()
    },
    trainingModel() {
      if (this.disableTraining) return
      if (this.isTraining) {
        this.workerStopTrainningModel()
        return
      }
      this.initializeHistorydata()
      if (!this.checkModelBeforeTrain()) return
      this.isTraining = true
      this.showLoading = true

      this.workerTraningModel({
        dataArray: this.dataArray,
        trainingData: this.trainingData,
        featuresArray: this.featuresArray,
        testingData: this.testingData,
        predictIndex: this.predictIndex,
        formData: this.formData,
        validatingPercent: this.validatingPercent,
        predictData: this.predictData,
      })
    },
    predictModel() {
      if (this.disablePredicting) return
      this.showLoading = true
      this.predictTraces = []
      this.workerPredictModel({ predictData: this.predictData })
    },
    async importMModel(files) {
      if (!this.checkModelBeforeTrain()) return
      this.predictTraces = []
      this.removeModel()
      let isModelFilesValid = this.checkModelFiles(files)
      if (!isModelFilesValid) return
      const contents = await readFilesBase64(files)
      const fileContents = contents.map((content, index) => ({ name: files[index].name, content }))
      window.localStorage.setItem('modelFiles', JSON.stringify(fileContents))
      this.workerImportModel({
        dataArray: this.dataArray,
        trainingData: this.trainingData,
        featuresArray: this.featuresArray,
        testingData: this.testingData,
        predictIndex: this.predictIndex,
        formData: this.formData,
        validatingPercent: this.validatingPercent,
        predictData: this.predictData,
      })
    },
    exportLSTMModel() {
      if (this.firstSelectedItem && this.modelInfo) {
        this.workerExportModel(this.firstSelectedItem, this.selectedDatasources[0].name.slice(0, -4))
      } else {
        this.$toast({ component: ToastificationContent, props: { title: 'Export model failed', text: this.$i18n.t('model_not_found'), icon: 'SlashIcon', variant: 'danger' } })
      }
    },
    initializeHistorydata() {
      this.batchTrainingProgress = {}
      this.epochTrainingProgress = {}
      this.batchEndData = [{ name: 'loss', type: 'scattergl', mode: 'lines', connectgaps: false, x: [], y: [] }]
      this.epochEndData = [
        { name: 'loss', type: 'scattergl', mode: 'lines', connectgaps: false, x: [], y: [] },
        { name: 'val_loss', type: 'scattergl', mode: 'lines', connectgaps: false, x: [], y: [] },
      ]
    },
    downloadImage() {
      this.asImage(this.tempProject.name + '_Machine Learning - Origin', this.$refs.refChart ? this.$refs.refChart.$refs['real-data-chart'] : undefined)
      this.asImage(this.tempProject.name + '_Machine Learning - Predict', this.$refs.refChart && this.predictTraces.length ? this.$refs.refChart.$refs['predict-data-chart'] : undefined)
      this.asImage(this.tempProject.name + '_Machine Learning - Batch End', this.$refs.refMlAdvancedConfig && this.batchEndData.length && this.batchEndData[0].x.length ? this.$refs.refMlAdvancedConfig.$refs.refBatchendChart : undefined)
      this.asImage(this.tempProject.name + '_Machine Learning - Epoch End', this.$refs.refMlAdvancedConfig && this.epochEndData.length && this.epochEndData[0].x.length ? this.$refs.refMlAdvancedConfig.$refs.refEpochendChart : undefined)
    },
    downloadHtml() {
      this.asHtml(this.tempProject.name + '_Machine Learning - Origin', this.$refs.refChart ? this.$refs.refChart.$refs['real-data-chart'] : undefined)
      this.asHtml(this.tempProject.name + '_Machine Learning - Predict', this.$refs.refChart && this.predictTraces.length ? this.$refs.refChart.$refs['predict-data-chart'] : undefined)
      this.asHtml(this.tempProject.name + '_Machine Learning - Batch End', this.$refs.refMlAdvancedConfig && this.batchEndData.length && this.batchEndData[0].x.length ? this.$refs.refMlAdvancedConfig.$refs.refBatchendChart : undefined)
      this.asHtml(this.tempProject.name + '_Machine Learning - Epoch End', this.$refs.refMlAdvancedConfig && this.epochEndData.length && this.epochEndData[0].x.length ? this.$refs.refMlAdvancedConfig.$refs.refEpochendChart : undefined)
    },
  },
}
</script>

<style lang="scss">
.machine-learning-container {
  overflow-x: hidden;
}
.machine-learning-container .train-advanced .vs__dropdown-toggle {
  height: 30px;
  display: flex;
  align-items: center;
}
.machine-learning-container .train-advanced .vs__actions .vs__clear {
  display: none;
}
.machine-learning-container .ml-model-config .b-avatar {
  cursor: pointer;
}
.machine-learning-container .ml-group-actions {
  display: flex;
  justify-content: space-evenly;
}
.machine-learning-container .ml-model-config div.media-aside[disabled] .b-avatar,
.machine-learning-container .ml-model-config div.media-aside[disabled] .model-actions_item {
  opacity: 0.5;
  cursor: not-allowed;
}
.machine-learning-container .model-actions .b-avatar:not(.model-icon) {
  width: 100% !important;
  padding: 7px;
}
.machine-learning-container .model-actions .model-actions_item {
  // color: #397bff;
  padding: 7px;
  text-align: start;
  border: 1px solid rgba(115, 103, 240, 0.24);
  border-radius: 0.357rem;

  padding: 4px;
  display: flex;
  position: relative;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.machine-learning-container .model-actions .model-actions_item-config-model {
  background-color: rgba(57, 123, 255, 0.12);
  border: 1px solid rgba(57, 123, 255, 0.12);
}
.machine-learning-container .model-actions .model-actions_item .spinner-model {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 12px;
  height: 12px;
}
.machine-learning-container .model-actions .model-actions_item .toolbar-title {
  margin-bottom: 4px;
}
.machine-learning-container .model-actions .model-actions_item:focus .ml-advanced-config-container,
.machine-learning-container .model-actions .model-actions_item:focus-within .ml-advanced-config-container,
.machine-learning-container .model-actions .model-actions_item .ml-advanced-config-container:hover {
  transform: scale(1);
  opacity: 1;
}
.machine-learning-container .model-actions .b-avatar .action-title {
  text-transform: uppercase;
  margin-left: 7px;
}
</style>
