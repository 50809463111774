<template>
  <div class="ml-advanced-config-container" :class="{ 'dark-layout': isDark }">
    <div class="ml-advanced-config-main">
      <div class="model-container mb-1">
        <h2>{{ $t('model') }}</h2>
        <b-form-file size="lg" accept=".zip" @input="uploadFiles" id="id-form-model-file" style="display: none" />
        <b-button variant="flat-primary" class="model-select-container" :class="{ 'has-model': !!modelInfo }" :disabled="isDisabled">
          <div class="model-pointer text-left" v-ripple.400="'rgba(57, 123, 255, 0.15)'" title="">
            <b-media no-body class="w-100 justify-content-between">
              <div class="d-flex align-items-center">
                <b-media-aside @click.stop="exportModel" v-if="modelInfo" :title="$t('download_model')">
                  <b-avatar class="model-icon" rounded size="42" variant="light-success">
                    <feather-icon size="18" icon="DownloadIcon" :style="{ color: '#28c76f' }" />
                  </b-avatar>
                </b-media-aside>
                <b-media-aside v-else title="" @click="openFiles">
                  <b-avatar class="model-icon" rounded size="42" variant="light-primary">
                    <feather-icon size="18" icon="UploadIcon" :style="{ color: '#397bff' }" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body>
                  <div v-if="modelInfo">
                    <h6 :title="$t('model_ready')" class="model-title">{{ $t('model_ready') }}</h6>
                    <small class="model-description">
                      <span>{{ modelInfo.loss ? `Loss ${modelInfo.loss}` : '' }}</span>
                    </small>
                  </div>
                  <div v-else>
                    <h6 class="model-title">{{ $t('empty_model') }}</h6>
                    <small class="model-description">
                      <span>{{ $t('upload_model') }}</span>
                    </small>
                  </div>
                </b-media-body>
              </div>
              <div class="d-flex align-items-center">
                <b-avatar :title="$t('evaluate') + ' ' + $t('model')" v-if="modelInfo" class="model-icon btn-model-evaluate" rounded variant="light-success" @click.native.stop="evaluateModel">
                  <b-spinner small v-if="isEvaluating" />
                  <feather-icon icon="CheckIcon" size="16" v-else />
                  <span class="action-title">{{ $t('evaluate') }}</span>
                </b-avatar>
                <b-avatar rounded class="model-icon btn-model-delete" variant="light-danger" :title="$t('delete')" @click.native.stop="removeModel">
                  <feather-icon size="16" icon="XIcon" />
                </b-avatar>
              </div>
            </b-media>
          </div>
        </b-button>
      </div>
      <div class="model-config-container custom-scrollbar">
        <div class="train-container">
          <h3>{{ $t('train') }}</h3>
          <div class="train-type">
            <b-form-group class="mb-1">
              <RadioGroup :name="`mi-advanced-config-model-type`" :label="$t('model_type')" ref="refModelType" :options="modelTypesChoices" v-model="modelType" />
            </b-form-group>
            <b-form-group class="mb-1" :disabled="modelType !== 'ML'">
              <label class="toolbar-title text-primary">{{ $t('feature_items') }}</label>
              <vue-select label="title" :disabled="modelType !== 'ML'" class="multiple-selection" :multiple="true" v-model="featureItems" :options="featureItemsChoices" />
            </b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-group class="mb-1">
                  <CustomInput v-model="epoch" label="epochs" size="sm" type="number" />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group class="mb-1">
                  <CustomInput v-model="lookBack" label="look_back" size="sm" type="number" />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group class="mb-1">
                  <CustomInput v-model="batchSize" label="batch_size" size="sm" type="number" />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group class="mb-1">
                  <CustomInput v-model="learningRate" label="learning_rate" size="sm" type="number" />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <app-collapse class="train-advanced">
            <app-collapse-item :title="$t('advanced_settings')">
              <b-row>
                <b-col cols="6">
                  <b-form-group class="mb-1">
                    <label class="toolbar-title text-primary">{{ $t('activations') }}</label>
                    <vue-select size="sm" :appendToBody="true" label="title" :reduce="(activation) => activation.value" v-model="activation" :options="activationChoices" :calculatePosition="calculatePosition" />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group class="mb-1">
                    <label class="toolbar-title text-primary">{{ $t('optimizers') }}</label>
                    <vue-select size="sm" :appendToBody="true" :reduce="(optimizer) => optimizer.value" label="title" v-model="optimizer" :options="optimizerChoices" :calculatePosition="calculatePosition" />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group class="mb-1">
                    <label class="toolbar-title text-primary">{{ $t('loss_functions') }}</label>
                    <vue-select size="sm" :appendToBody="true" :reduce="(lossFunction) => lossFunction.value" label="title" v-model="lossFunction" :options="lossFunctionChoices" :calculatePosition="calculatePosition" />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group class="mb-1">
                    <label class="toolbar-title text-primary">{{ $t('na_action') }}</label>
                    <vue-select size="sm" :appendToBody="true" :reduce="(naAction) => naAction.value" label="title" v-model="naAction" :options="naActionChoices" :calculatePosition="calculatePosition" />
                  </b-form-group>
                </b-col>
              </b-row>
            </app-collapse-item>
          </app-collapse>
        </div>
        <div class="predict-container">
          <h3>{{ $t('predict') }}</h3>
          <b-form-group class="mb-1">
            <CustomInput v-model="predictFuture" label="predict_steps" size="sm" type="number" />
          </b-form-group>
          <app-collapse class="under-the-hood">
            <app-collapse-item :title="$t('under_the_hood')">
              <template #header>
                <span class="lead collapse-title"><feather-icon icon="BarChart2Icon" style="margin-right: 4px"></feather-icon> {{ $t('under_the_hood') }}</span>
              </template>
              <div class="chart-history-ml mt-1">
                <div class="history-progress mb-1">
                  <div class="progress-item" style="margin-bottom: 6px">
                    <label for=""
                      >{{ $t('batch') }} <span v-if="Object.keys(batchTrainingProgress).length">({{ batchTrainingProgress.current }}/{{ batchTrainingProgress.total }})</span></label
                    >
                    <b-progress style="height: 6px" :value="batchTrainingProgress.percentage" max="100" />
                  </div>
                  <div class="progress-item">
                    <label for=""
                      >{{ $t('epoch') }} <span v-if="Object.keys(epochTrainingProgress).length">({{ epochTrainingProgress.current }}/{{ epochTrainingProgress.total }})</span></label
                    >
                    <b-progress class="progress-bar-success" style="height: 6px" :value="epochTrainingProgress.percentage" max="100" />
                  </div>
                </div>
                <b-card no-body style="margin-bottom: 6px">
                  <b-card-body>
                    <div class="batch-end-chart" ref="refBatchendChart"></div>
                  </b-card-body>
                </b-card>
                <b-card no-body>
                  <b-card-body class="position-relative">
                    <div class="epoch-end-chart" ref="refEpochendChart"></div>
                    <div class="custom-legend">
                      <div class="custom-legend-item">
                        <div class="legend-line loss"></div>
                        <div class="legend-text">loss</div>
                      </div>
                      <div class="custom-legend-item">
                        <div class="legend-line val_loss"></div>
                        <div class="legend-text">val_loss</div>
                      </div>
                    </div>
                  </b-card-body>
                </b-card>
              </div>
            </app-collapse-item>
          </app-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import JSZip from 'jszip';
import Ripple from 'vue-ripple-directive';
import { ThemeConfig } from '@/mixins/ThemeMixin.js';
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue';
import CustomInput from '../../common/CustomInput.vue';
import RadioGroup from '../../common/RadioGroup.vue';

export default {
  props: ['isEvaluating', 'batchTrainingProgress', 'epochTrainingProgress', 'isShow', 'value', 'totalCount', 'firstSelectedItem', 'modelInfo', 'isDisabled', 'itemsChoices'],
  components: { CustomInput, RadioGroup, AppCollapse, AppCollapseItem, ToastificationContent },
  mixins: [ThemeConfig],
  directives: { Ripple },
  data() {
    return {
      isHoverIcon: false,
      // modelType: 'UL',
      modelTypesChoices: [
        { text: 'Univariate LSTM', value: 'UL' },
        { text: 'Multivariate LSTM', value: 'ML' },
      ],
      // featureItems: [],
      modelConfig: this.value.modelConfig || 'simple',
      modelConfigChoices: [
        { text: 'Simple', value: 'simple' },
        { text: 'Advanced', value: 'advanced' },
      ],
      activationChoices: [
        { title: 'Linear', value: 'linear' },
        { title: 'ELU', value: 'elu' },
        { title: 'ReLU', value: 'relu' },
        { title: 'SELU', value: 'selu' },
        { title: 'Hard Sigmoid', value: 'hardSigmoid' },
        { title: 'Sigmoid', value: 'sigmoid' },
        { title: 'Soft Max', value: 'softmax' },
        { title: 'Soft Plus', value: 'softplus' },
        { title: 'Soft Sign', value: 'softsign' },
        { title: 'Tanh', value: 'tanh' },
        { title: 'Swish', value: 'swish' },
        { title: 'Mish', value: 'mish' },
      ],
      // optimizer: this.value.optimizer || 'adam',
      optimizerChoices: [
        { title: 'Adam', value: 'adam' },
        { title: 'Adamax', value: 'adamax' },
        { title: 'SGD', value: 'sgd' },
        { title: 'Adagrad', value: 'adagrad' },
        { title: 'Adadelta', value: 'adadelta' },
        { title: 'RMS prop', value: 'rmsprop' },
      ],
      // lossFunction: this.value.lossFunction || 'meanSquaredError',
      lossFunctionChoices: [
        { title: 'Mean Squared Error', value: 'meanSquaredError' },
        { title: 'Mean Absolute Error', value: 'meanAbsoluteError' },
        { title: 'Mean Absolute Percentage Error', value: 'meanAbsolutePercentageError' },
        { title: 'Mean Squared Logarithmic Error', value: 'meanSquaredLogarithmicError' },
        { title: 'Cosine Proximity', value: 'cosineProximity' },
        { title: 'Binary Crossentropy', value: 'binaryCrossentropy' },
      ],
      metric: this.value.metric,
      metricsChoices: [
        { title: 'Binary Accuracy', value: 'binary_accuracy' },
        { title: 'Binary Crossentropy', value: 'binary_crossentropy' },
        { title: 'Categorical Accuracy', value: 'categorical_accuracy' },
        { title: 'Categorical Crossentropy', value: 'categorical_crossentropy' },
        { title: 'Cosine Proximity', value: 'cosine_proximity' },
        { title: 'Hinge', value: 'hinge' },
        { title: 'Kullback Leibler Divergence', value: 'kullback_leibler_divergence' },
        { title: 'Mean Absolute Error', value: 'mean_absolute_error' },
        { title: 'Mean Absolute Percentage Error', value: 'mean_absolute_percentage_error' },
        { title: 'Mean Squared Error', value: 'mean_squared_error' },
        { title: 'Mean Squared Logarithmic Error', value: 'mean_squared_logarithmic_error' },
        { title: 'Poisson', value: 'poisson' },
        { title: 'Sparse Categorical Crossentropy', value: 'sparse_categorical_crossentropy' },
        { title: 'Squared Hinge', value: 'squared_hinge' },
        { title: 'Top K Categorical Accuracy', value: 'top_k_categorical_accuracy' },
        { title: 'Sparse Top K Categorical Accuracy', value: 'sparse_top_k_categorical_accuracy' },
      ],
      // learningRate: this.value.learningRate || 0.001,
      // naAction: this.value.naAction || 'bfill',
      naActionChoices: [
        { title: 'Backward Fill', value: 'bfill' },
        { title: 'Forward Fill', value: 'ffill' },
        { title: 'Remove NA', value: 'remove' },
        { title: 'Set NA = 0', value: 'zero' },
      ],
    };
  },
  computed: {
    activation: {
      get() {
        return this.$store.state.tabs.machine_learning.activation;
      },
      set(activation) {
        this.$store.commit(`tabs/SET_MACHINE_LEARNING`, { activation });
      },
    },

    optimizer: {
      get() {
        return this.$store.state.tabs.machine_learning.optimizer;
      },
      set(optimizer) {
        this.$store.commit(`tabs/SET_MACHINE_LEARNING`, { optimizer });
      },
    },

    lossFunction: {
      get() {
        return this.$store.state.tabs.machine_learning.lossFunction;
      },
      set(lossFunction) {
        this.$store.commit(`tabs/SET_MACHINE_LEARNING`, { lossFunction });
      },
    },

    learningRate: {
      get() {
        return this.$store.state.tabs.machine_learning.learningRate;
      },
      set(learningRate) {
        this.$store.commit(`tabs/SET_MACHINE_LEARNING`, { learningRate });
      },
    },

    naAction: {
      get() {
        return this.$store.state.tabs.machine_learning.naAction;
      },
      set(naAction) {
        this.$store.commit(`tabs/SET_MACHINE_LEARNING`, { naAction });
      },
    },

    modelType: {
      get() {
        return this.$store.state.tabs.machine_learning.modelType;
      },
      set(modelType) {
        this.$store.commit(`tabs/SET_MACHINE_LEARNING`, { modelType });
      },
    },

    featureItems: {
      get() {
        return this.$store.state.tabs.machine_learning.featureItems;
      },
      set(featureItems) {
        this.$store.commit(`tabs/SET_MACHINE_LEARNING`, { featureItems });
      },
    },

    epoch: {
      get() {
        return this.$store.state.tabs.machine_learning.epoch;
      },
      set(epoch) {
        this.$store.commit(`tabs/SET_MACHINE_LEARNING`, { epoch });
      },
    },

    lookBack: {
      get() {
        return this.$store.state.tabs.machine_learning.lookBack;
      },
      set(lookBack) {
        this.$store.commit(`tabs/SET_MACHINE_LEARNING`, { lookBack });
      },
    },

    predictFuture: {
      get() {
        return this.$store.state.tabs.machine_learning.predictFuture;
      },
      set(predictFuture) {
        this.$store.commit(`tabs/SET_MACHINE_LEARNING`, { predictFuture });
      },
    },

    batchSize: {
      get() {
        return this.$store.state.tabs.machine_learning.batchSize;
      },
      set(batchSize) {
        this.$store.commit(`tabs/SET_MACHINE_LEARNING`, { batchSize });
      },
    },

    // Select Options
    featureItemsChoices() {
      return this.itemsChoices;
    },
    options() {
      return {
        modelConfig: this.modelConfig,
        epoch: Number(this.epoch),
        lookBack: Number(this.lookBack),
        predictFuture: Number(this.predictFuture),
        batchSize: Number(this.batchSize),
        activation: this.activation,
        optimizer: this.optimizer,
        lossFunction: this.lossFunction,
        metric: this.metric,
        learningRate: Number(this.learningRate),
        naAction: this.naAction,
        modelType: this.modelType,
        featureItems: this.featureItems,
      };
    },
  },
  watch: {
    options() {
      this.$emit('input', this.options);
    },
    // Filter Feature exclude selected Item
    firstSelectedItem() {
      this.featureItems = this.featureItems.filter((o) => o !== (this.firstSelectedItem || {}).item);
    },
  },
  mounted() {
    this.$emit('input', this.options);
  },
  methods: {
    exportModel() {
      if (this.isDisabled) return;
      this.$emit('exportModel');
    },
    openFiles() {
      if (this.isDisabled) return;
      document.getElementById('id-form-model-file').click();
    },
    evaluateModel() {
      if (this.isDisabled) return;
      this.$emit('evaluateModel');
    },
    removeModel() {
      if (this.isDisabled) return;
      this.$emit('removeModel');
    },
    async uploadFiles(file) {
      let zip = new JSZip();
      try {
        await zip.loadAsync(file);
        let files = [];
        for (let el in zip.files) {
          let fobj = await zip.files[el].async('blob');
          files.push(new File([fobj], el));
        }
        let jsonFile = files.findIndex((el) => el.name.split('.').pop() === 'json');
        if (jsonFile == -1) {
          this.$toast({ component: ToastificationContent, props: { title: 'Upload model failed', text: this.$i18n.t('zip_doesnt_match'), icon: 'SlashIcon', variant: 'danger' } });
          return;
        }
        jsonFile = files.splice(jsonFile, 1);
        const modelFiles = [...jsonFile, ...files];
        if (modelFiles.length != 2) {
          this.$toast({ component: ToastificationContent, props: { title: 'Upload model failed', text: this.$i18n.t('zip_doesnt_match'), icon: 'SlashIcon', variant: 'danger' } });
          return;
        }
        this.$emit('importModel', [...jsonFile, ...files]);
      } catch (error) {
        this.$toast({ component: ToastificationContent, props: { title: 'Upload model failed', text: this.$i18n.t('invalid_zip_file_message'), icon: 'SlashIcon', variant: 'danger' } });
        return;
      }
    },
    // hàm này để làm cho vue-select hiện full option - không bị ellipsis
    calculatePosition(dropdownList, _component, calculated) {
      dropdownList.style.top = calculated.top;
      dropdownList.style.left = calculated.left;
      dropdownList.style.width = calculated.width;
      dropdownList.style.minWidth = 'fit-content';
    },
  },
};
</script>

<style>
.chart-history-ml {
  padding-bottom: 20px;
}
.chart-history-ml .card {
  border: none !important;
  background-color: rgba(57, 123, 255, 0.12) !important;
  border-radius: 0.357rem;
}
.batch-end-chart,
.epoch-end-chart {
  height: 122px;
  width: 100%;
}
.model-select-container {
  padding: 0;
  position: relative;
  width: 300px;
  border: none;
  outline: none;
  cursor: default !important;
  background-color: rgba(57, 123, 255, 0.12) !important;
  border-radius: 0.357rem;
  color: #5e5873;
}
.model-select-container:disabled .model-pointer,
.model-select-container:disabled .model-icon {
  cursor: not-allowed !important;
}

.model-select-container .model-pointer {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.model-select-container .model-pointer .media {
  align-items: center !important;
}

.model-select-container .model-title {
  white-space: nowrap;
  text-align: left;
  margin-bottom: 0.3rem;
  text-overflow: ellipsis;
  max-width: 100px;
  overflow: hidden;
}

.model-select-container .model-description {
  color: #397bff;
}
.ml-advanced-config-main {
  background-color: white;
  border-radius: 7px;
  padding: 10px;
}
.dark-layout .ml-advanced-config-main {
  background-color: #283046;
}

.ml-advanced-config-container {
  cursor: initial;
  position: absolute;
  left: 0;
  top: 0;
  transform: scale(0);
  opacity: 0;
  transform-origin: 150px 0;
  z-index: 1;
  border-radius: 7px;
  box-shadow: rgb(0 0 0 / 25%) 0px 25px 50px -12px;
  padding: 10px;
  width: 100%;
  margin-top: 40px;
  transition: transform 0.4s, opacity 0.4s;
  backdrop-filter: blur(2px);
  background-color: #fff;
  width: 650px;
  z-index: 11;
  backdrop-filter: blur(2px);
  background-color: rgba(57, 123, 255, 0.12) !important;
}
.ml-advanced-config-container.dark-layout {
  background-color: #283046;
}
.ml-advanced-config-container .ml-slider {
  margin-bottom: 30px;
}
/* .ml-advanced-config-container .vs__dropdown-toggle .vs__selected {
  display: flex !important;
} */
.model-config-container {
  display: flex;
  overflow-y: auto;
  max-height: 60vh;
  overflow-x: hidden;
}
.model-config-container .train-container,
.model-config-container .predict-container {
  width: 50%;
  padding-left: 10px;
}
.model-config-container .train-container {
  border-right: 1px solid #d8d6de;
  padding-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
}
.dark-layout .train-container {
  border-color: #404656;
}
/* .model-config-container .train-container .vs__selected-options {
  width: 80%;
}
.model-config-container .train-container .v-select:not(.multiple-selection) .vs__selected-options .vs__selected {
  display: block !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
} */
.model-config-container .predict-container {
  padding-left: 25px;
}
.model-config-container .train-advanced .card-header:after {
  left: 0;
}
.ml-advanced-config-container .btn-model-evaluate {
  height: 24px;
  width: unset !important;
  margin-right: 6px;
  color: #28c76f !important;
  padding: 6px;
}
.ml-advanced-config-container .btn-model-evaluate .b-avatar-custom {
  height: unset;
}
.ml-advanced-config-container .btn-model-delete {
  height: 24px;
  width: 24px !important;
  color: #ea5455 !important;
  opacity: 0;
  transform: opacity 0.25s ease;
}
.ml-advanced-config-container .model-select-container.has-model:not(disabled):hover .btn-model-delete {
  opacity: 1;
}
.model-config-container .train-advanced .card-header span {
  margin-left: 4px;
}
.model-config-container .train-advanced .card-body,
.model-config-container .under-the-hood .card-header,
.model-config-container .under-the-hood .card-body {
  padding: 0 !important;
}
.chart-history-ml .custom-legend {
  position: absolute;
  bottom: -19px;
  display: flex;
  left: 50%;
  transform: translate(-50%, 0);
}
.chart-history-ml .custom-legend .custom-legend-item {
  display: flex;
  align-items: center;
}
.chart-history-ml .custom-legend .custom-legend-item:nth-child(1) {
  margin-right: 20px;
}
.chart-history-ml .custom-legend .custom-legend-item .legend-line {
  width: 15px;
  height: 3px;
  background-color: rgb(255, 127, 14);
  margin-right: 6px;
}
.chart-history-ml .custom-legend .custom-legend-item .legend-line.loss {
  background-color: rgb(31, 119, 180);
}
.chart-history-ml .custom-legend .custom-legend-item .legend-line.val_loss {
  background-color: rgb(255, 127, 14);
}
.chart-history-ml .custom-legend .custom-legend-item .legend-text {
  color: #5e5873;
}
.dark-layout .chart-history-ml .custom-legend .custom-legend-item .legend-text {
  color: #d0d2d6;
}
</style>
