<template>
  <div class="d-flex ml-summary-container">
    <div class="d-flex ml-summary-counter mr-2">
      <b-media no-body v-for="item in summaryItems" class="mr-1" :class="{'is-na': item.isNa}" @click="handleClick(item)" :key="item.icon">
        <b-media-aside class="mr-1">
          <b-avatar size="38" :variant="item.color">
            <feather-icon size="24" :icon="item.icon" />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto">
          <h5 class="font-weight-bolder mb-0" style="min-width: 51px;">
            {{ item.title }}
          </h5>
          <span class="font-small-2 mb-0 text-nowrap">
            {{ item.subtitle }}
          </span>
        </b-media-body>
      </b-media>
    </div>
    <div class="ml-summary-slider">
      <MlSliderProcess @change="handleSliderChange" ref="refMlSliderProcess" />
    </div>
    <div class="summary-mobile">
      <div class="summary-counter-mobile d-none">
        <div class="summary-counter-button">
          <DynamicAnimate position="bottom-right">
            <b-media-aside :title="$t('click_to_show_summary')">
              <b-avatar size="28" variant="light-primary">
                <feather-icon size="18" icon="DiscIcon" />
              </b-avatar>
            </b-media-aside>
            <template #content>
              <div class="summary-background-wrapper summary-counter-wrapper">
                <div class="summary-background-content">
                  <b-media no-body v-for="item in summaryItems" class="mr-1" :class="{'is-na click-not-close': item.isNa}" @click="handleClick(item)" :key="item.icon">
                    <b-media-aside class="mr-1">
                      <b-avatar size="38" :variant="item.color">
                        <feather-icon size="24" :icon="item.icon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h5 class="font-weight-bolder mb-0" style="min-width: 51px;">
                        {{ item.title }}
                      </h5>
                      <span class="font-small-2 mb-0 text-nowrap">
                        {{ item.subtitle }}
                      </span>
                    </b-media-body>
                  </b-media>
                </div>
              </div>
            </template>
          </DynamicAnimate>
        </div>
      </div>
      <div class="summary-slider-mobile d-none">
        <DynamicAnimate position="bottom-right" :title="$t('click_to_show_summary')">
          <div class="summary-slider-button">
            <div class="summary-slider-button_icon"></div>
          </div>
          <template #content>
            <div class="summary-background-wrapper summary-slider-wrapper">
              <div class="summary-background-content">
                <MlSliderProcess @change="handleSliderChange" ref="refMlSliderProcess" />
              </div>
            </div>
          </template>
        </DynamicAnimate>
      </div>
    </div>

  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'
import DynamicAnimate from '../../common/DynamicAnimate.vue'
import MlSliderProcess from './MlSliderProcess.vue'

const NA_DISPLAY_TYPES = {
ORIGINAL: 'original',
PERCENTAGE: 'pergentage',
}

export default {
props: ['summary'],
components: { DynamicAnimate, VueSlider, MlSliderProcess },
data() {
  return {
    naDisplayType: NA_DISPLAY_TYPES.ORIGINAL,
  }
},
computed: {
  naValueDisplay() {
    if (this.naDisplayType === NA_DISPLAY_TYPES.ORIGINAL) {
      return this.summary.na
    } else {
      const totalCount = parseFloat(this.summary.originalTotalCount)
      const naCount = parseFloat(this.summary.na)
      return !totalCount ? '0%' : `${parseFloat((naCount / totalCount * 100).toFixed(2))}%`
    }
  },
  summaryItems() {
    return [{
        icon: 'DiscIcon',
        color: 'light-primary',
        title: this.summary.originalTotalCount,
        subtitle: this.$i18n.t('count'),
        customClass: 'mb-2 mb-xl-0',
      }, {
        icon: 'SlashIcon',
        color: 'light-danger',
        title: this.naValueDisplay,
        subtitle: this.$i18n.t('NA'),
        isNa: true,
        customClass: 'mb-2 mb-xl-0',
      },
    ]
  },
},
methods: {
  handleClick(item) {
    if (item.isNa) {
      this.naDisplayType = this.naDisplayType === NA_DISPLAY_TYPES.ORIGINAL ? NA_DISPLAY_TYPES.PERCENTAGE : NA_DISPLAY_TYPES.ORIGINAL
    }
  },
  handleSliderChange(data) {
    this.$emit('sliderChange', data)
  },
},
}
</script>

<style>
.ml-summary-container {
margin-right: 40px;
}
.ml-summary-container .summary-slider {
width: 300px;
}
.ml-summary-container .vue-slider {
margin-bottom: 10px !important;
}
.ml-summary-container .vue-slider-mark-label {
font-size: 0.8rem;
}
.ml-summary-container .is-na {
cursor: pointer;
}

@media (max-width: 1200px) {
.menu-hide .ml-summary-container  ,.menu-open .ml-summary-container{
  margin-right: 0 !important;
}
.menu-hide .ml-summary-container .ml-summary-counter ,.menu-open .ml-summary-container .ml-summary-counter {
  margin-right: 0 !important;
}
.menu-hide .ml-summary-container .ml-summary-slider ,.menu-open .ml-summary-container .ml-summary-slider  {
  display: none !important;
}
.menu-hide .ml-summary-container .summary-slider-mobile  ,.menu-open .ml-summary-container .summary-slider-mobile{
  display: flex !important;
}
.menu-hide .ml-summary-container .summary-mobile  ,.menu-open .ml-summary-container .summary-mobile{
  display: flex;
}
}
@media (max-width: 963px) {
.menu-hide .ml-summary-container .ml-summary-counter , .menu-open .ml-summary-container .ml-summary-counter {
  display: none !important;
}
.menu-hide .ml-summary-container .summary-counter-mobile  ,  .menu-open .ml-summary-container .summary-counter-mobile {
  display: flex !important;
}
}

/* SLIDER */
@media (max-width: 1271px) {
.menu-collapsed .ml-summary-container {
  margin-right: 0 !important;
}
.menu-collapsed .ml-summary-container .ml-summary-counter {
  margin-right: 0 !important;
}
.menu-collapsed .ml-summary-container .ml-summary-slider {
  display: none !important;
}
.menu-collapsed .ml-summary-container .summary-slider-mobile {
  display: flex !important;
}
.menu-collapsed .ml-summary-container .summary-mobile {
  display: flex;
}
}
@media (max-width: 1453px) {
.menu-expanded .ml-summary-container {
  margin-right: 0 !important;
}
.menu-expanded .ml-summary-container .ml-summary-counter {
  margin-right: 0 !important;
}
.menu-expanded .ml-summary-container .ml-summary-slider {
  display: none !important;
}
.menu-expanded .ml-summary-container .summary-slider-mobile {
  display: flex !important;
}
.menu-expanded .ml-summary-container .summary-mobile {
  display: flex;
}
}
</style>
