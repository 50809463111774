<template>
  <div class="ml-advanced-config-container ml-advanced-settings-container" :class="{ 'dark-layout': isDark }">
    <div class="ml-advanced-config-main" :style="{ minHeight: `170px` }">
      <div class="model-container mb-1">
        <b-row class="mb-1">
          <b-col cols="6">
            <div class="label-form-group">
              <family-icon style="width: 16px; height: 16px; margin-right: 10px; color: #397bff" /> <span>{{ $t('font_family') }}</span>
            </div>
            <b-form-group :label="``">
              <vue-select :options="chartFontFamilyChoices" v-model="chartFontFamily" />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <div class="label-form-group">
              <size-icon style="width: 16px; height: 16px; margin-right: 10px; color: #397bff" /><span>{{ $t('text_size') }}</span>
            </div>
            <b-form-group :label="``">
              <b-form-input v-model="chartTextSize" :placeholder="$t('text_size')" type="number" />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="label-form-group">
              <color-icon style="width: 16px; height: 16px; margin-right: 10px; color: #397bff" /><span>{{ $t('text_color') }}</span>
            </div>
            <b-form-group :label="``">
              <ColorPicker v-model="chartTextColor" :placeholder="$t('text_color')" />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import ColorPicker from '../../common/ColorPicker.vue';

import { ThemeConfig } from '@/mixins/ThemeMixin.js';

// icon
import FamilyIcon from '/static/images/layer-config/prepend/font-family.svg';
import ColorIcon from '/static/images/layer-config/prepend/font-color.svg';
import SizeIcon from '/static/images/layer-config/prepend/font-size.svg';

export default {
  props: ['nameComponent'],
  components: { ColorPicker, FamilyIcon, ColorIcon, SizeIcon },
  mixins: [ThemeConfig],
  data() {
    return {
      chartFontFamilyChoices: ['Noto Sans JP', 'Calibri', 'Sans Serif', 'Serif', 'Cursive', 'Monospace'],
    };
  },
  computed: {
    chartFontFamily: {
      get() {
        try {
          return this.$store.state.tabs[this.nameComponent].chartFontFamily;
        } catch {
          return this.$store.state.settings.chartFontFamily;
        }
      },
      set(chartFontFamily) {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { chartFontFamily });
      },
    },
    chartTextSize: {
      get() {
        try {
          return this.$store.state.tabs[this.nameComponent].chartTextSize;
        } catch {
          return this.$store.state.settings.chartTextSize;
        }
      },
      set(chartTextSize) {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { chartTextSize });
      },
    },
    chartTextColor: {
      get() {
        try {
          return this.$store.state.tabs[this.nameComponent].chartTextColor;
        } catch {
          return this.$store.state.settings.chartTextColor;
        }
      },
      set(chartTextColor) {
        this.$store.commit(`tabs/SET_${this.nameComponent.toUpperCase()}`, { chartTextColor });
      },
    },
  },
};
</script>

<style>
.ml-advanced-config-main {
  background-color: white;
  border-radius: 7px;
  padding: 10px;
}
.dark-layout .ml-advanced-config-main {
  background-color: #283046;
}

.ml-advanced-settings-container {
  cursor: initial;
  position: absolute;
  left: -155px;
  top: 15px;
  transform: scale(0);
  opacity: 0;
  transform-origin: 150px 0;
  z-index: 1;
  border-radius: 7px;
  box-shadow: rgb(0 0 0 / 25%) 0px 25px 50px -12px;
  padding: 10px;
  width: 100%;
  margin-top: 40px;
  transition: transform 0.4s, opacity 0.4s;
  backdrop-filter: blur(2px);
  background-color: #fff;
  width: 350px;
  z-index: 11;
  backdrop-filter: blur(2px);
  background-color: rgba(57, 123, 255, 0.12) !important;
}
.ml-advanced-config-container.dark-layout {
  background-color: #283046;
}
.ml-advanced-config-container .ml-slider {
  margin-bottom: 30px;
}
.label-form-group {
  padding-bottom: calc(0.438rem + 1px);
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #808080;
}
</style>
