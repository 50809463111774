<template>
  <b-card no-body class="machine-learning-chart-container">
    <b-card-body>
      <div class="machine-learning-chart" id="machine-learning-plotly-chart-train" ref="real-data-chart" v-show="showChart"></div>
      <div class="machine-learning-chart" v-show="!showChart">
        <EmptyChart :description="$t('tips_machine_learning')" />
      </div>
      <div class="machine-learning-chart" id="machine-learning-plotly-chart-predict" ref="predict-data-chart" v-show="showChartPredict"></div>
    </b-card-body>
  </b-card>
</template>

<script>
const _ = require('lodash')
import Plotly from 'plotly.js-dist'
import EmptyChart from '../../common/EmptyChart.vue'
import { CustomLayout } from '@/mixins/ThemeMixin.js'

const LAYOUT = {
  hovermode: 'closest',
  yaxis: { automargin: true },
  xaxis: { automargin: true },
}
const CONFIG = { displaylogo: false, responsive: true, editable: true, modeBarButtonsToRemove: ['toImage'] }
import { locale } from '@/scripts/plotly/locale.js'
import i18n from '@/libs/i18n'

export default {
  props: ['chartList', 'nameComponent'],
  mixins: [CustomLayout],
  components: { EmptyChart, locale },
  data() {
    return {
      funcEventPlotlyRelayoutTrain: null,
      funcEventPlotlyRelayoutPredict: null,
    }
  },
  mounted() {
    'undefined' == typeof Plotly ? ((window.PlotlyLocales = window.PlotlyLocales || []), window.PlotlyLocales.push(locale)) : Plotly.register(locale)
    this.updateChart()

    new ResizeObserver(() => {
      try {
        if (document.getElementsByClassName('machine-learning-chart')[0] && this.$refs['real-data-chart'] && this.$refs['real-data-chart'].style.display !== 'none') Plotly.Plots.resize(this.$refs['real-data-chart'])
        if (document.getElementsByClassName('machine-learning-chart')[0] && this.$refs['predict-data-chart'] && this.$refs['predict-data-chart'].style.display !== 'none') Plotly.Plots.resize(this.$refs['predict-data-chart'])
      } catch {}
    }).observe(this.$refs['real-data-chart'])
  },
  computed: {
    locale() {
      return i18n.locale
    },
    showChart() {
      return this.chartList[0] && this.chartList[0].traces.length
    },
    showChartPredict() {
      return this.chartList.length === 2 && this.chartList[1].traces.length
    },
    initProject() {
      return this.$store.state.ecoplot.initProject
    },
    chartFontFamily() {
      return this.$store.state.tabs[this.nameComponent].chartFontFamily
    },
    chartTextSize() {
      return this.$store.state.tabs[this.nameComponent].chartTextSize
    },
    chartTextColor() {
      return this.$store.state.tabs[this.nameComponent].chartTextColor
    },
    chartFont() {
      let font = {}
      try {
        if (this.chartFontFamily) {
          font.family = this.chartFontFamily
        }
        if (this.chartTextSize) {
          font.size = this.chartTextSize
        }
        if (this.chartTextColor) {
          font.color = this.chartTextColor
        }
      } catch {}
      return font
    },
    chartLayoutTrain() {
      return this.$store.state.tabs.machine_learning.chartLayoutTrain
    },
    chartLayoutPredict() {
      return this.$store.state.tabs.machine_learning.chartLayoutPredict
    },
  },
  watch: {
    initProject() {
      try {
        if (this.$refs['real-data-chart']) {
          if (this.$refs['real-data-chart'].layout) {
            if (this.$refs['real-data-chart'].layout.xaxis.title) delete this.$refs['real-data-chart'].layout.xaxis.title
            if (this.$refs['real-data-chart'].layout.yaxis.title) delete this.$refs['real-data-chart'].layout.yaxis.title
          }
        }
      } catch {}
      try {
        if (this.$refs['predict-data-chart']) {
          if (this.$refs['predict-data-chart'].layout) {
            if (this.$refs['predict-data-chart'].layout.xaxis.title) delete this.$refs['predict-data-chart'].layout.xaxis.title
            if (this.$refs['predict-data-chart'].layout.yaxis.title) delete this.$refs['predict-data-chart'].layout.yaxis.title
          }
        }
      } catch {}
    },
    locale() {
      if (this.chartList[0].traces.length > 0) {
        this.updateChart()
      }
    },
    flagTheme() {
      try {
        let fontFamily = this.chartFont.family
        try {
          fontFamily = this.chartFontFamily
        } catch {}
        let fontSize = this.chartFont.size
        try {
          fontSize = this.chartTextSize
        } catch {}
        let fontColor = this.chartFont.color
        try {
          fontColor = this.chartTextColor
        } catch {}
        let updateLight = {
          'font.family': fontFamily,
          'font.size': fontSize,
          'font.color': fontColor,
          paper_bgcolor: 'transparent',
          plot_bgcolor: 'transparent',
          'modebar.bgcolor': 'transparent',
          'modebar.color': 'rgba(0,0,0,0.5)',
          'modebar.activecolor': 'rgba(0,0,0,1)',
          'xaxis.gridcolor': '#eeeeee',
          'xaxis.zerolinecolor': '#b4b7bd',
          'xaxis1.gridcolor': '#eeeeee',
          'xaxis1.zerolinecolor': '#b4b7bd',
          'xaxis2.gridcolor': '#eeeeee',
          'xaxis2.zerolinecolor': '#b4b7bd',
          'xaxis3.gridcolor': '#eeeeee',
          'xaxis3.zerolinecolor': '#b4b7bd',
          'xaxis4.gridcolor': '#eeeeee',
          'xaxis4.zerolinecolor': '#b4b7bd',
          'yaxis.gridcolor': '#eeeeee',
          'yaxis.zerolinecolor': '#b4b7bd',
          'yaxis1.gridcolor': '#eeeeee',
          'yaxis1.zerolinecolor': '#b4b7bd',
          'yaxis2.gridcolor': '#eeeeee',
          'yaxis2.zerolinecolor': '#b4b7bd',
          'yaxis3.gridcolor': '#eeeeee',
          'yaxis3.zerolinecolor': '#b4b7bd',
          'yaxis4.gridcolor': '#eeeeee',
          'yaxis4.zerolinecolor': '#b4b7bd',
        }
        let updateDark = {
          'font.family': this.chartFont.family,
          'font.size': this.chartFont.size,
          'font.color': this.chartFont.color,
          paper_bgcolor: 'transparent',
          plot_bgcolor: 'transparent',
          'modebar.bgcolor': 'transparent',
          'modebar.color': 'rgba(255,255,255,0.5)',
          'modebar.activecolor': 'rgba(255,255,255,1)',
          'xaxis.gridcolor': '#42465d',
          'xaxis.zerolinecolor': '#b4b7bd',
          'xaxis1.gridcolor': '#42465d',
          'xaxis1.zerolinecolor': '#b4b7bd',
          'xaxis2.gridcolor': '#42465d',
          'xaxis2.zerolinecolor': '#b4b7bd',
          'xaxis3.gridcolor': '#42465d',
          'xaxis3.zerolinecolor': '#b4b7bd',
          'xaxis4.gridcolor': '#42465d',
          'xaxis4.zerolinecolor': '#b4b7bd',
          'yaxis.gridcolor': '#42465d',
          'yaxis.zerolinecolor': '#b4b7bd',
          'yaxis1.gridcolor': '#42465d',
          'yaxis1.zerolinecolor': '#b4b7bd',
          'yaxis2.gridcolor': '#42465d',
          'yaxis2.zerolinecolor': '#b4b7bd',
          'yaxis3.gridcolor': '#42465d',
          'yaxis3.zerolinecolor': '#b4b7bd',
          'yaxis4.gridcolor': '#42465d',
          'yaxis4.zerolinecolor': '#b4b7bd',
        }
        if (this.$refs['real-data-chart']) Plotly.relayout(this.$refs['real-data-chart'], this.isDark ? updateDark : updateLight)
        if (this.$refs['predict-data-chart']) Plotly.relayout(this.$refs['predict-data-chart'], this.isDark ? updateDark : updateLight)
      } catch {}
    },
    chartList: {
      deep: true,
      handler() {
        this.updateChart(true)
      },
    },
    chartFont: {
      deep: true,
      handler() {
        if (this.chartFont) {
          this.updateChart()
        }
      },
    },
    chartFont: {
      deep: true,
      handler() {
        if (this.chartFont) {
          this.updateChartLayout()
        }
      },
    },
    $route: {
      deep: true,
      handler() {
        try {
          if (this.$route.name == 'machine-learning') {
            this.eventPlotlyLayoutTrain()
            this.eventPlotlyLayoutPredict()
          }
        } catch {}
      },
    },
  },
  methods: {
    eventPlotlyLayoutTrain() {
      const self = this
      try {
        if (self.funcEventPlotlyRelayoutTrain) {
          clearTimeout(self.funcEventPlotlyRelayoutTrain)
        }
        self.funcEventPlotlyRelayoutTrain = setTimeout(() => {
          let plotlyElement = document.getElementById('machine-learning-plotly-chart-train')
          if (plotlyElement) {
            plotlyElement.on('plotly_relayout', (data) => {
              try {
                let layoutOrigin = plotlyElement.layout
                let layoutTemp = {
                  xaxis: {},
                  yaxis: {},
                }
                if (layoutOrigin.title) {
                  layoutTemp.title = layoutOrigin.title
                }
                if (layoutOrigin.xaxis && layoutOrigin.xaxis.title) {
                  layoutTemp.xaxis.title = layoutOrigin.xaxis.title
                }
                if (layoutOrigin.yaxis && layoutOrigin.yaxis.title) {
                  layoutTemp.yaxis.title = layoutOrigin.yaxis.title
                }
                if (self.$route && self.$route.name == 'machine-learning') {
                  self.$store.commit(`tabs/SET_MACHINE_LEARNING`, { chartLayoutTrain: layoutTemp })
                }
              } catch {}
            })
          }
        }, 1000)
      } catch {}
    },
    eventPlotlyLayoutPredict() {
      const self = this
      try {
        if (self.funcEventPlotlyRelayoutPredict) {
          clearTimeout(self.funcEventPlotlyRelayoutPredict)
        }
        self.funcEventPlotlyRelayoutPredict = setTimeout(() => {
          let plotlyElement = document.getElementById('machine-learning-plotly-chart-predict')
          if (plotlyElement) {
            plotlyElement.on('plotly_relayout', (data) => {
              try {
                let layoutOrigin = plotlyElement.layout
                let layoutTemp = {
                  xaxis: {},
                  yaxis: {},
                }
                let isEmptyChartPredict
                try {
                  if (self.chartList) {
                    for (const chart of this.chartList) {
                      if (chart.uuid && chart.uuid == 'predict-data-chart') {
                        isEmptyChartPredict = true
                        break
                      }
                    }
                  }
                } catch {}
                if (isEmptyChartPredict) {
                  layoutTemp.title = layoutOrigin.title
                } else {
                  try {
                    delete layoutTemp.title
                  } catch {}
                }
                // if (layoutOrigin.title) {
                //   layoutTemp.title = layoutOrigin.title
                // }
                if (layoutOrigin.xaxis && layoutOrigin.xaxis.title) {
                  layoutTemp.xaxis.title = layoutOrigin.xaxis.title
                }
                if (layoutOrigin.yaxis && layoutOrigin.yaxis.title) {
                  layoutTemp.yaxis.title = layoutOrigin.yaxis.title
                }
                if (isEmptyChartPredict && self.$route && self.$route.name == 'machine-learning') {
                  self.$store.commit(`tabs/SET_MACHINE_LEARNING`, { chartLayoutPredict: layoutTemp })
                }
              } catch {}
            })
          }
        }, 1000)
      } catch {}
    },
    updateChart(isUpdate = false) {
      let _config = CONFIG
      _config.locale = i18n.locale
      let layoutTemp = {}
      try {
        if (this.chartFont) {
          layoutTemp = _.merge(layoutTemp, { font: this.chartFont })
        }
      } catch {}
      for (const chart of this.chartList) {
        let layoutTempTrain = _.cloneDeep(layoutTemp)
        let layoutTempPredict = _.cloneDeep(layoutTemp)
        try {
          if (chart.uuid && chart.uuid == 'real-data-chart' && this.chartLayoutTrain) {
            layoutTempTrain = _.merge(layoutTempTrain, this.chartLayoutTrain)
          }
          if (chart.uuid && chart.uuid == 'predict-data-chart' && this.chartLayoutPredict) {
            layoutTempPredict = _.merge(layoutTempPredict, this.chartLayoutPredict)
          }
        } catch {}
        if (isUpdate) {
          try {
            let layout = {}
            if (chart.uuid && chart.uuid == 'real-data-chart') {
              layout = _.merge({}, this.$refs[chart.uuid].layout, this.themeLayout, LAYOUT, chart.layout, layoutTempTrain)
            } else {
              layout = _.merge({}, this.$refs[chart.uuid].layout, this.themeLayout, LAYOUT, chart.layout, layoutTempPredict)
            }
            Plotly.react(this.$refs[chart.uuid], chart.traces, layout, _config)
          } catch (err) {}
        } else {
          let layout
          if (chart.uuid && chart.uuid == 'real-data-chart') {
            layout = _.merge({}, this.themeLayout, LAYOUT, chart.layout, layoutTempTrain)
          } else {
            layout = _.merge({}, this.themeLayout, LAYOUT, chart.layout, layoutTempPredict)
          }
          Plotly.newPlot(this.$refs[chart.uuid], chart.traces, layout, _config)
          this.eventPlotlyLayoutTrain()
        }
        if (chart.uuid && chart.uuid == 'predict-data-chart') {
          this.eventPlotlyLayoutPredict()
        }
      }
    },
    updateChartLayout() {
      // setTimeout(() => {
      try {
        let layoutTrain = this.$refs['real-data-chart'].layout
        let layoutTempTrain = {}
        try {
          if (this.chartFont) {
            layoutTempTrain = _.merge(layoutTempTrain, { font: this.chartFont })
          }
          if (this.chartLayout) {
            layoutTempTrain = _.merge(layoutTempTrain, this.chartLayout)
          }
        } catch {}
        layoutTrain = _.merge(layoutTrain, layoutTempTrain)
        Plotly.relayout(this.$refs['real-data-chart'], layoutTrain)
        // Plotly.react(this.$refs.chart, this.$refs.chart.data, layout);
      } catch {}
      try {
        let layoutPredict = this.$refs['predict-data-chart'].layout
        let layoutTempPredict = {}
        try {
          if (this.chartFont) {
            layoutTempPredict = _.merge(layoutTempPredict, { font: this.chartFont })
          }
          if (this.chartLayout) {
            layoutTempPredict = _.merge(layoutTempPredict, this.chartLayout)
          }
        } catch {}
        layoutPredict = _.merge(layoutPredict, layoutTempPredict)
        Plotly.relayout(this.$refs['predict-data-chart'], layoutPredict)
        // Plotly.react(this.$refs.chart, this.$refs.chart.data, layout);
      } catch {}
      // }, 300);
    },
  },
}
</script>

<style>
.machine-learning-chart-container .card-body {
  padding-top: 0;
}
.machine-learning-chart-container {
  height: calc(100vh - 30px - 90.3px - 90px);
}
.machine-learning-chart-container .machine-learning-chart {
  height: 50%;
}
</style>
