const _ = require('lodash')
import { handleNaAction, getFileContent } from '@/views/ecoplot-desktops/tabs/machine-learning/helpers'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import MathMixin from '@/mixins/MathMixin.js'
const dayjs = require('dayjs')

const SECOND_IN_HOUR = 60 * 60
const SECOND_IN_DAY = 24 * SECOND_IN_HOUR
const SECOND_IN_MONTH = 28 * SECOND_IN_DAY
const SECOND_IN_YEAR = 365 * SECOND_IN_DAY

export default {
  components: { ToastificationContent },
  mixins: [MathMixin],
  data() {
    return {
      hasOpenedAdvancedConfig: false,
    }
  },
  computed: {
    // FLAGS
    flagSelectItem() {
      return `${this.firstSelectedItem}__${this.formData.naAction}`
    },
    itemsChoices() {
      if (this.selectedDatasources.length) {
        return Object.keys(this.selectedDatasources[0].items)
      } else {
        return []
      }
    },
    // Select Options
    featureItemsChoices() {
      return this.itemsChoices.filter((o) => o !== this.firstSelectedItem.item)
    },
    firstSelectedDatasource() {
      return this.selectedDatasources.length ? this.selectedDatasources[0] : null
    },
    frequencyDate() {
      if (this.firstSelectedDatasource) {
        const dates = this.firstSelectedDatasource.dates
        const firstDate = new Date(dayjs(dates[0]).valueOf())
        const secondDate = new Date(dayjs(dates[1]).valueOf())
        const secondsDiff = (secondDate.getTime() - firstDate.getTime()) / 1000

        if (secondsDiff >= SECOND_IN_YEAR) return 'yearly'
        else if (secondsDiff >= SECOND_IN_MONTH) return 'monthly'
        else if (secondsDiff >= SECOND_IN_DAY) return 'daily'
        else if (secondsDiff >= SECOND_IN_HOUR) return 'hourly'
      }
    },
    firstSelectedItem() {
      return this.datasourceSelected.length ? this.datasourceSelected[0] : null
    },
    dataArray() {
      if (this.originalDataArray.length) {
        return handleNaAction(this.originalDataArray, this.formData.naAction)
      }
      return []
    },
    originalTotalCount() {
      return this.originalDataArray.length
    },
    totalCount() {
      return this.dataArray.length
    },
    naCount() {
      return this.originalDataArray.filter((d) => this.isNaValue(d[1])).length
    },
    trainingCount() {
      const val = this.totalCount - this.validatingCount - this.testingCount
      return val < 0 ? 0 : val
    },
    validatingCount() {
      return Math.round((this.totalCount * this.validatingPercent) / 100)
    },
    testingCount() {
      const val = Math.round((this.totalCount * this.testingPercent) / 100)
      const remain = this.totalCount - this.validatingCount
      return val > remain ? remain : val
    },
    trainingData() {
      return this.dataArray.slice(0, this.trainingCount + this.validatingCount)
    },
    validateData() {
      return this.dataArray.slice(this.trainingCount, this.trainingCount + this.validatingCount)
    },
    testingData() {
      return this.dataArray.slice(this.trainingCount + this.validatingCount)
    },
    predictIndex() {
      return Math.floor((this.predictPercent * (this.totalCount - 1)) / 100)
    },
    predictData() {
      let result = []
      if (this.dataArray[this.predictIndex]) {
        const predict_value = this.dataArray[this.predictIndex][1]
        const predict_date = this.dataArray[this.predictIndex][0]

        let distance = ''

        if (this.frequencyDate == 'hourly') distance = 'h'
        else if (this.frequencyDate == 'daily') distance = 'd'
        else if (this.frequencyDate == 'monthly') distance = 'M'
        else distance = 'y'

        for (let i = 0; i < this.formData.predictFuture; i++) {
          result.push([
            dayjs(predict_date)
              .add(i + 1, distance)
              .format('YYYY-MM-DD'),
            predict_value,
          ])
        }
      }
      return result
    },
    selectedUnit() {
      return this.firstSelectedItem ? (this.firstSelectedDatasource[this.firstSelectedItem.item] || {}).unit : ''
    },
    realTraces() {
      if (this.firstSelectedItem) {
        let traces = []
        const trainingData = this.dataArray.slice(0, this.trainingCount)
        traces.push({ name: this.$i18n.t('training'), x: trainingData.map((i) => i[0]), y: trainingData.map((i) => i[1]), type: 'scatter', mode: 'lines', line: { color: '#337ab7' }, hovertemplate: `(%{x|%Y/%m/%d %H:%M:%S}, %{y})` })
        traces.push({ name: this.$i18n.t('validate'), x: this.validateData.map((i) => i[0]), y: this.validateData.map((i) => i[1]), type: 'scatter', mode: 'lines', line: { color: '#ff9f43' }, hovertemplate: `(%{x|%Y/%m/%d %H:%M:%S}, %{y})` })
        traces.push({ name: this.$i18n.t('testing'), x: this.testingData.map((i) => i[0]), y: this.testingData.map((i) => i[1]), type: 'scatter', mode: 'lines', line: { color: '#5cb85c' }, hovertemplate: `(%{x|%Y/%m/%d %H:%M:%S}, %{y})` })
        traces.push({ name: this.$i18n.t('predict'), x: this.predictData.map((i) => i[0]), y: this.predictData.map((i) => i[1]), type: 'scatter', mode: 'lines', line: { color: '#b85cb5', dash: 'dash' }, hovertemplate: `(%{x|%Y/%m/%d %H:%M:%S}, %{y})` })
        return traces
      }
      return []
    },
    chart() {
      return { uuid: 'real-data-chart', traces: this.realTraces, layout: { title: this.$i18n.t('real_data') } }
    },
    predictChart() {
      return { uuid: 'predict-data-chart', traces: this.predictTraces, layout: { title: this.$i18n.t('predict_data') } }
    },
    chartList() {
      if (this.predictTraces.length > 0) {
        return [this.chart, this.predictChart]
      } else {
        return [this.chart]
      }
    },
    summaryML() {
      return {
        originalTotalCount: this.originalTotalCount,
        na: this.naCount,
        validatingPercent: this.validatingPercent,
        testingPercent: this.testingPercent,
        predictPercent: this.predictPercent,
      }
    },
  },
  watch: {
    datasourceSelected() {
      if (this.datasourceSelected && this.datasourceSelected.length === 0) {
        this.predictTraces = []
      }
    },
  },
  asyncComputed: {
    originalDataArray: {
      default: [],
      async get() {
        if (this.firstSelectedDatasource) {
          // return await this.selectFrom(`${this.firstSelectedDatasource.id}%%${this.firstSelectedItem.item}`, null, (record) => [record.date, record[this.firstSelectedItem.location]])
          const locationItem = [this.firstSelectedItem.location + '-' + this.firstSelectedItem.item]
          const data = await this.selectAllByLocationsItems(this.firstSelectedDatasource.id, locationItem)
          return _.zip(...Object.values(data))
        } else {
          return []
        }
      },
    },
    featuresArray: {
      default: [],
      async get() {
        let featuresArray = []
        try {
          if (this.formData && this.formData.featureItems) {
            for (const item of this.formData.featureItems) {
              // let data = await this.selectFrom(`${this.firstSelectedDatasource.id}%%${item}`, null, (record) => [record.date, record[this.firstSelectedItem.location]])
              const locationItem = [this.firstSelectedItem.location + '-' + this.firstSelectedItem.item]
              const data = await this.selectAllByLocationsItems(this.firstSelectedDatasource.id, locationItem)
              const convertData = _.zip(...Object.values(data))
              featuresArray.push(handleNaAction(convertData, this.formData.naAction))
            }
          }
        } catch {}
        return featuresArray
      },
    },
  },
  methods: {
    resetModelTypeGroup() {
      if (!this.hasOpenedAdvancedConfig) {
        this.hasOpenedAdvancedConfig = true
        setTimeout(() => {
          this.$refs.refMlAdvancedConfig.$refs.refModelType.moveIndicator()
        }, 300)
      }
    },
    handleSliderChange(data) {
      this.validatingPercent = Number(data[1]) - Number(data[0])
      this.testingPercent = Number(100) - Number(data[1])
      this.predictPercent = Number(data[2])
    },
    checkModelBeforeTrain() {
      const lookback = this.formData.lookBack
      const compareWith = this.trainingData.length < this.testingData.length ? this.trainingData.length : this.testingData.length
      if (compareWith <= lookback) {
        this.$toast({ component: ToastificationContent, props: { title: this.$t('training_model_failed'), text: this.$t('lookback_smaller_message', { value: compareWith }), icon: 'ActivityIcon', variant: 'danger' } })
        return false
      } else if (lookback === 1) {
        this.$toast({ component: ToastificationContent, props: { title: this.$t('training_model_failed'), text: this.$t('lookback_range_message'), icon: 'ActivityIcon', variant: 'danger' } })
        return false
      }
      return true
    },
    async checkModelFiles(files) {
      let isValid = true
      const content = await getFileContent(files[0])

      let startIndex = content.indexOf('"batch_input_shape"')
      let endIndex = content.indexOf('"dtype"')
      let text = content.slice(startIndex, endIndex - 2).split(',')
      const inputShape = Number(text[2])
      const lookBack = Number(text[1])

      if (isNaN(inputShape) || isNaN(lookBack)) {
        this.$toast({ component: ToastificationContent, props: { title: this.$t('import_model_failed'), text: this.$i18n.t('zip_doesnt_match'), icon: 'SlashIcon', variant: 'danger' } })
        return false
      }
      const features = this.formData.featureItems.length + 1
      if (features != inputShape) {
        this.$toast({ component: ToastificationContent, props: { title: this.$t('import_model_failed'), text: this.$i18n.t('model_inputshape_message'), icon: 'SlashIcon', variant: 'danger' } })
        isValid = false
      }
      if (Number(this.formData.lookBack) != lookBack) {
        this.$toast({ component: ToastificationContent, props: { title: this.$t('import_model_failed'), text: this.$i18n.t('lookback_difference'), icon: 'SlashIcon', variant: 'danger' } })
        isValid = false
      }
      return isValid
    },
  },
}
