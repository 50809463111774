<template>
  <div class="ml-slider-process-container">
    <VueSlider ref="refSlider" class="mb-3" @dragging="handleDragging" v-model="value" :marks="true" :order="false" :process="process">
      <template v-slot:mark="{ pos, label }">
        <div class="vue-slider-mark vue-slider-mark-active" :style="{ left: `${pos}%` }">
          <div class="vue-slider-mark-step vue-slider-mark-step-active"></div>
          <div class="vue-slider-mark-label vue-slider-mark-label-active">{{ showLabel(label) }}</div>
        </div>
      </template>
      <template v-slot:dot="{ index, focus }">
        <div class="vue-slider-dot-handle" :class="{ 'vue-slider-dot-handle-focus': focus }"  :style="{ borderColor: colors[index] }"></div>
      </template>
      <template v-slot:process="{ style, start }">
        <div class="vue-slider-process" :style="trainingStyles(style, start)">
        </div>
        <div class="vue-slider-process" :style="style">
        </div>
      </template>
      <template v-slot:tooltip="{ index }">
        <div class="vue-slider-dot-tooltip-inner vue-slider-dot-tooltip-inner-top">
          <span class="vue-slider-dot-tooltip-text">{{ tooltips[index] }}</span>
        </div>
      </template>
    </VueSlider>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component'

export default {
  components: { VueSlider },
  data() {
    return {
      colors: ['#397bff', '#ff9f43', '#b66dff'],
      value: [60, 80, 100],
      process: dotsPos => [
        [dotsPos[0], dotsPos[1], { backgroundColor: '#ff9f43' }],
      ],
    }
  },
  computed: {
    tooltips() {
      return [this.$i18n.t('training'), this.$i18n.t('validation'), this.$i18n.t('prediction')]
    }
  },
  watch: {
    value() {
      this.$emit('change', this.value)
    },
  },
  methods: {
    handleDragging(value, index) {
      if (index === 0 && value[0] >= value[1]) {
        this.value[0] = this.value[1]
        this.$refs.refSlider.setValue(this.value)
      } else if (index === 1 && value[1] <= value[0]) {
        this.value[1] = this.value[0]
        this.$refs.refSlider.setValue(this.value)
      }
    },
    trainingStyles(style, start) {
      style = {...style}
      style.backgroundColor = '#397bff'
      style.left = `0`
      style.width = `${start}%`
      return style
    },
    showLabel(label) {
      return this.value.indexOf(label) === -1 ? '' : `${label}%`
    },
  },
}
</script>

<style>
.ml-slider-process-container {
  width: 230px;
  margin-top: 4px;
}
.ml-slider-process-container .vue-slider-marks {
  background-color: #28c76f;
}
.ml-slider-process-container .merge-tooltip {
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translate(-50%, -15px);
}
.ml-slider-process-container .vue-slider-dot-tooltip-top {
  top: -6px !important;
}
</style>
